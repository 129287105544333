import styled from "styled-components";

export const Container = styled.div`
     width: 100%;
     height: auto;

     display: flex;
     align-items: center;
     flex-direction: column;
`;

export const Wrapper = styled.div` 
     width: 100%;
     height: auto;
     padding: 0 1rem 4rem 1rem;
     
     gap: 2.5rem;
     display: flex;
     align-items: center;
     flex-direction: column;

     background-color: #ffff;
`;

export const ContainerCupom = styled.div`
     width: 100%;
     
     gap: 2rem;
     display:flex;
     align-items: center;
     flex-direction: column;
`;

export const CupomSecondSection = styled.div`
     width: 100%;
     max-width: 40rem;

     gap: 1.5rem;
     display: flex;
     margin-top: 1rem;
     flex-direction: column;

     span {
          text-align: center;
     }

     @media (max-width: 1178px) {
          align-items: center;
          flex-direction: column;
     }
`;

export const MainImage = styled.div`
          img {
          width: 100%;
          height: 7.5rem;
          max-height: 100%;
          max-width: 8.68rem;
     }
`;

export const CupomTitle = styled.div`
     width: 100%;
     max-width: 40rem;
          
     text-align: center;

     h1 {
          font-size: 2.25rem;
          font-weight: 800;

          @media (max-width: 479px) {
               font-size: 1.5rem;
          }
     }
`;

export const CupomText = styled.div`
     width: 100%;
     max-width: 43.75rem;

     text-align: center;

     p {
          @media (max-width: 479px) {
               font-size: 1rem;
          }
     }
`;

export const ViewCupom = styled.div`
     width: 100%;
     max-width: 23.625rem;
     padding: 1rem 1.5rem 1.25rem 1.5rem;
     
     display: flex;
     cursor: pointer;
     align-items: center;
     flex-direction: column;
     
     border-radius: 1rem;
     background-color: #F3F3F5;
     
`;

export const CupomLabel = styled.p`
     font-weight: 400;
     font-size: 0.75rem;
`;

export const Cupom = styled.h1`
     font-weight: 800;
     font-size: 1.5rem;
     text-transform: uppercase;

     @media (max-width: 350px) {
          font-size: 1rem;
     }
`;

export const Curiosity = styled.div`
     width: 100%;
     padding: 1.5rem;

     gap: 1.5rem;
     display: flex;
     align-items: center;
     
     text-align: center;
     border-radius: 1rem;
     background-color: #21262F;

     p {
          text-align: left;

          color: #FFFF;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.8rem;
          
          span {
               text-transform: uppercase;
          }
     }

     @media (max-width: 479px) {
          flex-direction: column;
     }

     @media (max-width: 386px) {
          padding: 1rem;

          p{
               font-size: 0.85rem;
          }
     }
`;

export const ButtonSection = styled.div`
     width: auto;
     max-width: 8.5rem;

     gap: 0.5rem;
     display: flex;
     flex-direction: column;

     button {
          all: unset;
          max-width: min-content;
          padding: 0.75rem 1.5rem;
          
          gap: 0.5rem;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          color: white;
          font-weight: 800;
          font-size: 0.625rem;
          border-radius: 40rem;
          letter-spacing: 0.1rem;
          background-color: #2FBB69;
     }

     @media (max-width: 479px) {
          max-width: fit-content;

          flex-direction: row;

          button {
               padding: 0.5rem 1rem;
          }
     }

     @media (max-width: 386px) {
          gap: 0.3rem;

          button {
               font-size: 0.5rem;
               padding: 0.7rem;
          }
     }

     @media (max-width: 320px) {
          button {
               padding: 0.5rem;
          }
     }
`;

export const Benefits = styled.div`
     gap: 1rem;
     width: 100%;

     display: flex;
     flex-direction: column;

     p {
          text-align: center;

          font-weight: 400;
          font-size: 0.75rem;
     }
`;

export const TableWrapper = styled.div`
     display: flex;
     flex-direction: column;

     border-radius: 1rem;
     border: 0.4rem solid #F8F8F9;
`;

export const LineInfo = styled.div`
     width: 100%;
     height: auto;
     padding: 1.5rem 0;
     
     display: flex;

     >span{
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 700;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }
`;

export const LineDescription = styled.div`
     width: 100%;
     height: auto;
     padding: 1rem 0;

     display: flex;

     background-color: #F8F8F9;

     >p{
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 400;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }

     >p:nth-of-type(1){
          width: 100%;
     }
`;

export const DescriptionandTooltip = styled.div`
    width: 100%;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

     >p{
          display: flex;
          align-items: center;
          justify-content: center;

          font-weight: 400;
          text-align: center;
          font-family: "Lato";
          font-size: 0.875rem;
          line-height: 1.3125rem;
     }
`;

export const ContainerModalBlocked = styled.div`
     >div>div{
          width: 100%;
          height: auto;
          max-width: 32.875rem;

          >div:nth-of-type(2) {
               >div>div{
                    >button{
                         font-size: 0.6875rem;
                    }
               }
          }

          @media (max-width: 843px) {
               max-width: 100%;
          }
     }
`;

export const ModalBlockedTitle = styled.h4`
     display: flex;
     margin-bottom: 1.5rem;

     text-align: center;

     font-weight: 800;
     font-size: 1.5rem;
     line-height: 1.95rem;
     font-family: 'Lato';
`;

export const ModalBlockedDescription = styled.p`
     display: flex;
     margin-bottom: 1.5rem;
     
     text-align: center;

     font-weight: 400;
     font-size: 1.125rem;
     line-height: 1.8rem;
     font-family: 'Lato';
`;