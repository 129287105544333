import React, {useState, useEffect} from 'react';
import { Arrow, Options, User } from './styles';
import { logout } from '../../services/auth';
import ArrowIcon from '../../assets/icons/icons-8-expand-arrow@3x.png';

import ModalGeneric from '../modal/modalGeneric';
import Button from '../button/button_v2';

export const USER = "@userinfo";
export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';

const Userinfo = ({ isBlackNovember, ...props }) => {

    console.log("No User:", isBlackNovember)
    
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState(0);
    const [userPlanId, setUserPlanId] = useState(0);
    const [diasRestantes, setDiasRestantes] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    const [hide, setHide] = useState(false);

    const [showExitModal, setShowExitModal] = useState(false);

    const [styleTitleModal] = useState({
        fontFamily: 'Lato',
        fontSize: '22px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    });
    
    const [styleTextModal] = useState({
        color: 'rgb(48, 56, 72)',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    });

    const logincompra = /\/login-compra\//g;
    const simulacaocompra = /\/simulacaoplano\//g;



    const openOptions = () => {
        setShowOptions(!showOptions);
        props.updateOpenUserOptions(!showOptions);
    }

    const exit = () => {
        if (userPlanId === 1) { //usuário de teste grátis
            if (diasRestantes === 7) {
                setShowExitModal(true);
            } else {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                setTimeout(() => {
                    setShowExitModal(false);
                    window.location = '/login';
                }, 100);
            }
        } else {
            localStorage.removeItem(GOOGLE_EMAIL);
            localStorage.removeItem(GOOGLE_USER);
            localStorage.removeItem(GOOGLE_USER_PROFILE);

            setTimeout(() => {
                window.location = '/login';
            }, 100);
        }
    }

    const goToPlans = () => {
        setShowExitModal(false);
        props.redirectTo('/planos');
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));

        if (user) {
            let name = user.name;
            let id = user.id;

            name = name.substr(0, 2).toUpperCase();
            setUsername(name);
            setUserId(id);
            if (user.contrato) {
                setUserPlanId(user.contrato.agro_plan_config.agro_plan_id);
                setDiasRestantes(user.contrato.dias_restantes);
            } else {
                logout();
                window.location = '/login';
            }
        }
    },[]);

    useEffect(() => {

        if (props.showOptions !== showOptions) {
            setShowOptions(props.showOptions);
        }
    },[props.showOptions])

    useEffect(() => {
        if (props.location === '/login' || props.location === '/compra/sucesso' || props.location === '/cupom-indique-e-ganhe' || props.location.search(logincompra) !== -1 ||  props.location.search(simulacaocompra) !== -1) {
            setHide(true);
        } else {
            setHide(false);
        }
    },[props.location])

    return (
        <>
            <User
                isBlackNovember={isBlackNovember}
                onClick={() => openOptions()}
                hide={hide}
                isMobile={props.isMobile}
                showOptions={showOptions}
                button={props.button}
            >
                <label>{username}</label>
                <img src={ArrowIcon} alt='seta' className='arrow' />
            </User>

            <Arrow 
                show={showOptions}
                isMobile={props.isMobile}
                button={props.button}
            />

            <Options
                show={showOptions}
                isMobile={props.isMobile}
                button={props.button}
            >
                <label onClick={() => props.redirectTo(`/usuarios/${userId}`)}>MEUS DADOS</label>
                {
                    props.isMobile && props.planId && props.planId !== 5 && (
                        <label onClick={() => props.redirectTo(`/planos`)}>ATUALIZAÇÃO DE PLANO</label>
                    )
                }
                <label onClick={() => props.redirectTo(`/faturamento`)}>DADOS DE FATURAMENTO</label>
                <label onClick={() => props.redirectTo(`/filiais`)}>MINHA EMPRESA</label>
                <label onClick={() => props.redirectTo(`/usuarios`)}>USUÁRIOS</label>
                <label onClick={() => props.redirectTo(`/integracao`)}>INTEGRAÇÃO</label>
                <label onClick={() => exit()}>SAIR</label>
            </Options>

            <ModalGeneric
                show={showExitModal}
                onHide={() => window.location = '/login'}
                size='md'
            >
                <h2 style={styleTitleModal}>{`Seu teste grátis expira em ${diasRestantes} dia(s)`}</h2>
                <p style={styleTextModal}> Utilize o cupom <b>TESTEGRATIS</b> e garanta já seu desconto! </p>
                <p style={styleTextModal}> Planos Light e Pro com 50% de desconto na primeira mensalidade e Plano Pré Pago com 20% de desconto na sua primeira compra! </p>
                <p style={styleTextModal}> Assine o Agroreceita para não perder os recursos abaixo: </p>
                <p style={styleTextModal}> Emissão de receitas </p>
                <p style={styleTextModal}> Controle de ARTs/TRTs</p>
                <p style={styleTextModal}> Relatórios completos </p>
                <p style={styleTextModal}> Envio de dados para as defesas agropecuárias e CREA SP </p>
                <p style={styleTextModal}> Cadastro de produtores e locais de aplicação </p>
                <p style={styleTextModal}> Cadastro de responsáveis técnicos </p>
                <p style={styleTextModal}> Impressão e consulta de fichas de emergência e FISPQs </p>
                <p style={styleTextModal}> Está com dúvidas sobre como usar nosso sistema? </p>
                <p style={styleTextModal}> Agende uma <span style={{ color: 'rgb(88, 155, 255)', cursor: 'pointer' }} onClick={() => {window.open('https://agroreceita.com.br/fale-com-especialista/', '_blank');}}><u>consultoria gratuita!</u></span> </p>

                <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row'}}>
                    <Button style={{ outline:1, padding: "5px", width:"166px", height: "45px" }}
                        outline={1}
                        color="#21262F"
                        onClick={() =>  window.location = '/login'}
                        value='SAIR'
                    />

                    <Button style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
                        color="#00b43f"
                        onClick={() =>  goToPlans()}
                        value='VEJA OS PLANOS'
                    />
                
                </div>

            </ModalGeneric>
        </>
    )
}

export default Userinfo;

