import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";

import Logo from '../../assets/logo-agroreceita-branco.svg';
import Email from '../../assets/imagem-email-sucesso.svg';

import { theme } from '../../styles/theme';
import { BodyG, BodyGSpan, H1 } from '../../styles/stylesTypography';

import * as S from './styleSucess';

const SuccessTest = () => {
    const inscriptionEmail = localStorage.getItem('@inscription_user_email')

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [width]);

    const textsEmail = () => {
        if (width <= 767) {
            return (
                <S.TextWithEmail>
                    <BodyG color={theme.colors.gray.Cinza_9}>Enviamos um e-mail para</BodyG>

                    <BodyGSpan color={theme.colors.white.Branco}>{inscriptionEmail}</BodyGSpan>
                </S.TextWithEmail>
            )
        } else {
            return (
                <S.TextWithEmail>
                    <BodyG color={theme.colors.gray.Cinza_9}>Enviamos um e-mail para</BodyG>

                    <BodyGSpan color={theme.colors.white.Branco}>{inscriptionEmail}</BodyGSpan>

                    <BodyG color={theme.colors.gray.Cinza_9}>.</BodyG>
                </S.TextWithEmail>
            )
        }
    }

    return (
        <S.Container>
            <S.Wrapper>
                <S.Description>
                    <img src={Logo} alt="Imagem logo da empresa" />

                    <S.ContainerTextWithEmail>
                        <H1 color={theme.colors.white.Branco}>Acesse seu e-mail para<br /> validar sua conta!</H1>

                        {textsEmail()}

                        <BodyG color={theme.colors.gray.Cinza_9}>Caso não encontre nosso e-mail, confira sua caixa de spam.</BodyG>
                    </S.ContainerTextWithEmail>
                </S.Description>

                <img src={Email} alt="Imagem envelope de email" />
            </S.Wrapper>
        </S.Container>
    );
}

export default withRouter(SuccessTest);
