import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: auto;
    padding: 2rem 2rem 0;

    gap: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    background-color: #FFFF;

    @media (max-width: 767px) {
        padding: 1rem 1rem 0;
    }

    @media (max-width: 479px) {
        gap: 2.5rem;
    }
`;

export const ContainerWelcome = styled.div`
    width: 100%;
    height: auto;
    max-width: 58.75rem;

    display: flex;
    align-items: center;

    >h3{
        text-align: center;

        @media (max-width: 479px) {
            font-size: 1.5rem;
     }
    }
`;

export const ContainerFooter = styled.div`
    width: 100%;

    margin: 1rem 0;
`;

export const ContainerTable = styled.div`
    width: 100%;
    height: auto;
    max-width: 71.25rem;

    display: flex;
    margin-top: 0.5rem;

    @media (max-width: 767px) {
        gap: 1rem;
        align-items: center;
        flex-direction: column;
    }
`;

export const Benefits = styled.div`
    width: 100%;
    height: auto;
    max-width: 20.9375rem;

    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        max-width: 100%;
        align-items: center;
    }
`;

export const TableInformation = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    border-radius: 1rem;
    border: 0.25rem solid #F8F8F9;
`;

export const LineInfo = styled.div`
    width: 100%;
    height: auto;
    padding: 1.5rem 0;
    
    display: flex;

    >span{
        width: 100%;
        max-width: 16.3125rem;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }
`;

export const LineDescription = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0;

    display: flex;

    background-color: #F8F8F9;

    >p{
        width: 100%;
        max-width: 16.3125rem;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }
`; 

export const DescriptionandTooltip = styled.div`
    width: 100%;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    >p{
        width: 100%;
        max-width: 16.3125rem;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }
`;

export const ContainerService = styled.div`
    width: 100%;
    height: auto;

    gap: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
`;

export const Service = styled.div`
    width: 100%;
    height: auto;

    gap: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const CardService = styled.div`
    width: 100%;
    height: auto;
    max-width: 17.5rem;

    display: flex;
    gap: 2.1875rem;
    flex-direction: column;

    >p, >h6, >a > h6{
        text-align: center;
    }
`;
