import styled from "styled-components";

const NotFoundStyle = styled.div`
    width: 100vw;
    position: fixed;
    z-index: 9999;

    .header{
        background-color: #21262f;
        height: 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img{
            width: 150px;
        }
    }

    .container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .box{
            width: 550px;
            margin-top: 80px;

            h1{
                font-size: 45px;
                margin-top: 20px;
                text-align: center;

            }
            p{
                color: #777;
                text-align: center;
            }

            .buttons{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                flex-wrap: wrap;

                margin-top: 80px;
                a{
                    text-decoration: none;
                    margin-bottom: 15px;
                }
            }

            img{
                width: 500px;
            }
        }
    }
`;

export default NotFoundStyle;