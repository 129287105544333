import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    >div:nth-of-type(2){
        >div{
            max-width: 32.875rem;
            max-height: 33.938rem;

            scrollbar-width: none;
            scrollbar-color: #ffffff transparent;

            >div:nth-of-type(2){
                >h1{
                    font-size: 1.5rem;
                    color: #303848;
                    font-weight: 800;
                    text-align: center;
                    line-height: 1.3em;
                    font-family: "Lato";
                }
            }

            @media (max-width: 843px) {
                max-width: 100%;
            }
        }
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    max-width: 71.25rem;

    display: flex;
    margin-right: 1rem;
    align-items: center;
    flex-direction: column;
`;

export const ContainerContent = styled.div`
    width: 100%;
    height: auto;
    padding: 2rem;

    gap: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    border-radius: 2rem;
    background-color: #F8F8F9;
    border: 0.5rem solid #2FBB69;
    box-shadow: 0.5rem 0.5rem 0 0.5rem #06B04D;
`;

export const ContentHeader = styled.div`
    width: 100%;
    height: auto;

    gap: 1.25rem;
    display: flex;

    >p{
        width: 100%;
        max-width: 49.0625rem;
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export const ContenTitle = styled.div`
    width: 100%;
    height: auto;
    max-width: 14.6875rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >h2{
        text-align: justify;
    }
`;

export const ContainerCards = styled.div`
    width: 100%;
    height: auto;

    gap: 1rem;
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

export const Card = styled.div`
    width: 100%;
    height: auto;
    padding: 1.125rem;
    max-width: 16.0625rem;

    gap: 1rem;
    display: flex;
    flex-direction: column;

    border-radius: 1rem;
    background-color: #FFFFFF;

    @media (max-width: 767px) {
        max-width: 100%;
    }
`;

export const ContainerButtonAndTerms = styled.div`
    width: auto;
    height: auto;

    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    >p{
        color: #303848;
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 1.5em;
        font-family: "Lato";
    }
`;

export const LinkContainer = styled.div`
    all: unset;
    width: auto;
    height: auto;

    cursor: pointer;

    >a{
        cursor: pointer;
        
        color: #589BFF;
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 1.5em;
        font-family: "Lato";
    }
`;

export const ContainerModalBlocked = styled.div`

    >h5{
        margin-bottom: 1.5rem;

        text-align: center;
    }

    >p{
        margin-bottom: 1.5rem;

        text-align: center;
    }

     >div>div{
          width: 100%;
          height: 50px;
          max-width: 296px;

          >div:nth-of-type(2) {
               >div>div{
                    >button{
                         font-size: 0.6875rem;
                    }
               }
          }

          @media (max-width: 843px) {
               max-width: 100%;
          }
     }
`;