import styled from "styled-components";

export const FormContainer = styled.div`
  padding-top: 50;
  padding-bottom: 220px;

  background: #f8f8f9;

  div {
    form {
      max-width: 500px;
      min-width: 250px;
      padding-top: 50px;

      margin: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      
      button {
        width: 100%;
        height: 60px;
        max-width: 400px;
      
        margin: 15px;
      
        i {
          margin-left: 15px;
        }
      }
    
      
    }
  }

`;

export const ContainerSuccess = styled.div`
  position: relative;
  
  width: 100%;
  padding: 0 3rem;
  min-height: 100vh;

  gap: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: rgb(41, 46, 56);

  @media (max-width: 768px) {
      flex-direction: column;

      >img{
         display: none;
      }
    }
`;

export const ContainerLeft = styled.div`
  width: 100%;
  height: auto;
  max-width: 27.25rem;

  gap: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

    >img{
      width: auto;
      height: 2rem;
    }

    @media (max-width: 768px) {    
      gap: 2rem;
      align-items: center;
    }

    @media (max-width: 478px) {    
      gap: 0;
    }
`;

export const ContainerTextWithEmail = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    >h1{
      margin-bottom: 1rem;
    }

    p {
      margin: 0; 
    }

    @media (max-width: 768px) {
      >h1{
        text-align: center;
      }

      p{
        text-align: center;
      }
    }

    @media (max-width: 478px) {
      margin: 1rem 0 0;

      >h1{
        font-size: 1.5rem;
        line-height: 2rem;
      }

      p {
       font-size: 1rem;
       line-height: 1rem;
      }
    }
`;

export const TextWithEmail = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    p, span {
      color: #CED2DC;
      font-weight: 400;
      font-family: 'Lato';
      line-height: 1.8rem;
      font-size: 1.125rem;
    }

    span {
      margin: 0; 
      
      color: #FFFFFF;
      font-weight: 700; 
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 478px) {
      margin-bottom: 1rem;
      align-items: center;
      flex-direction: column;

      p, span {
        font-size: 1rem;
        line-height: 1.4rem;
      }
    }
`;

export const ButtonHome = styled.button`
   all:unset;

    width: 100%;
    height: 1.5rem;
    max-width: 9.8rem;
    padding: 0.75rem 2rem;

    display: flex;
    align-items: center;

    cursor: pointer;
    border-radius: 10rem;
    background-color: #2FBB69;

    >p{
      cursor: pointer;

      font-weight: 800;
      text-align: center;
    }
`;