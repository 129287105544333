import styled from "styled-components";

import BgBannerHeader from '../../../assets/bg-banner-header-lp-indique-e-ganhe.webp'

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 64px 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 1.5rem;
    border: 0.25rem solid #F8F8F9;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(${BgBannerHeader});

    @media (max-width: 767px) {
        padding: 2rem 0.5rem;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    max-width: 71.25rem;

    display: flex;
    align-items: center;
    flex-direction: column;

    >img{
        height: auto;
        width: 8.75rem;

        margin-bottom: 6.5rem;

        @media (max-width: 767px) {
            margin-bottom: 3rem;
        }
    }

    >h1{
        margin-bottom: 1.5rem;
        
        text-align: center;

        @media (max-width: 767px) {
            font-size: 1.75rem;
        }
    }

    >p{
        margin-bottom: 1.5rem;

        text-align: center;
    }

    >p + p{
        margin-top: 5rem;
        margin-bottom: 0;

        @media (max-width: 767px) {
            margin-top: 0;
        }
    }
`;
