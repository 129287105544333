import React from 'react'

import LogoBlack from '../../../assets/logo-agroreceita-black.svg'

import { theme } from '../../../styles/theme'
import { BodyG, H1, TaglineP } from '../../../styles/stylesTypography'

import * as S from './styles'

export function BannerHeader() {
    return (
        <S.Container>
            <S.Wrapper>
                <img src={LogoBlack} alt="Logo Agroreceita preto" />

                <H1>Programa Indique & Ganhe AgroReceita</H1>

                <BodyG color={theme.colors.gray.Cinza_5}>Ganhe benefícios incríveis ao indicar novos clientes para o AgroReceita.</BodyG>

                <TaglineP color={theme.colors.gray.Cinza_6}>Maior Banco de Dados para a emissão do Receituário Agronômico.</TaglineP>
            </S.Wrapper>
        </S.Container>
    )
}