import styled from "styled-components";

import BgLogo from '../../../assets/bg-simbolo-logo.svg'

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 4rem;

    border-radius: 1.75rem;
    background-color: #21262F;

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url(${BgLogo});

    @media (max-width: 767px){
        padding: 2rem;
    }

    @media (max-width: 479px){
        padding:  2rem 1rem;
    }

    >div:nth-of-type(2){
        >div{
            max-width: 32.875rem;
            max-height: 33.938rem;

            scrollbar-width: none;
            scrollbar-color: #ffffff transparent;

            >div:nth-of-type(2){
                >h1{
                    font-size: 1.5rem;
                    color: #303848;
                    font-weight: 800;
                    text-align: center;
                    line-height: 1.3em;
                    font-family: "Lato";
                }
            }

            @media (max-width: 843px) {
                max-width: 100%;
            }
        }
    }
`;

export const Wrapper = styled.div`
    width: auto;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    >h2{
        margin-bottom: 4rem;

        text-align: center;
    }
`;

export const ContainerQuestionsAndButton = styled.div`
    width: 100%;
    height: auto;

    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const CardQuestion = styled.div`
    width: 100%;
    height: auto;
    max-width: 49.625rem;

    display: flex;
    flex-direction: column;

    border-radius: .9375rem;
    background-color: #FFFFFF;   
`;

export const Question = styled.div`
    width: 100%;
    height: auto;
    padding: 1.975rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    >h5{
        cursor: pointer;
    }

    >img{
        width: 1.875rem;
        height: auto;
    }
`;

export const Answer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    max-height: 0;
    opacity: 0;
    overflow: hidden; 

    transition: max-height 0.4s ease, opacity 0.2s ease; 

    > p {
        padding: 0 1.875rem 1.875rem;
    }

    &.Active {
        max-height: 31.25rem; 
        opacity: 1; 
    }

    &.Hidden {
        max-height: 0; 
        opacity: 0;
    }
`;

export const ContainerButtonAndTerms = styled.div`
    width: auto;
    height: auto;

    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    >p>a{
        cursor: pointer;
        
        color: #589BFF;
    }
`;

export const ModalContent = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    margin-top: 2rem;
    flex-direction: column;
    align-items: flex-start;

    >h2{
        margin-bottom: -0.5rem;

        font-size: 1.25rem;
        color: #303848;
        font-weight: 800;
        text-align: center;
        line-height: 1.3em;
        font-family: "Lato";
    }

    >p{
        margin-bottom: .5rem;

        font-size: 1rem;
        color: #303848;
        font-weight: 400;
        text-align: center;
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ModalSectionTerms = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0 0 1rem;

    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;

        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ModalSectionTermsAndTopics = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;
        
        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ModalTopics = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0 0 1rem;

    gap: 1.5rem;
    display: flex;
    margin-bottom: 1.5rem;
    flex-direction: column;

    >p{
        text-align: justify;
        
        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ModalSectionTopics = styled.div`
    width: 100%;
    height: auto;
    padding: 0 0 0 1rem;

    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;
        
        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ModalSectionTopicsList = styled.div`
    width: 100%;
    height: auto;
    padding: 0 0 0 1rem;

    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;
        
        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }

    >ul{
        padding: 1rem 0 0 2rem;

        display: flex;
        margin-bottom: 1.5rem;
        flex-direction: column;
        align-items: flex-start;

        >li {
            text-align: justify;

            font-size: 1rem;
            color: #303848;
            font-weight: 400; 
            line-height: 1.3em;
            font-family: "Lato";
        }
    }
`;

export const ModalSubTerms = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;

        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const Author = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    >p{
        text-align: justify;

        font-size: 1rem;
        color: #303848;
        font-weight: 400; 
        line-height: 1.3em;
        font-family: "Lato";
    }
`;

export const ContainerModalBlocked = styled.div`
    >h5, >p{
        display: flex;
        margin-bottom: 1.5rem;

        text-align: center;
    }

     >div>div{
          >div:nth-of-type(2) {
               >div>div{
                    >button{
                         font-size: 0.6875rem;
                    }
               }
          }

          @media (max-width: 843px) {
               max-width: 100%;
          }
     }
`;