import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { store } from 'react-notifications-component';

import api from "../../services/api";

import Button from '../../components/button/button_v2';
import HeaderV2 from '../../components/headerV2/index.js';
import ModalGeneric from '../../components/modal/modalGeneric';

import { buttonData } from './ButtonData.js';

import MainImage from '../../assets/image-garota-na-ponte.svg';

import * as S from './styles';
import { Auxiliar, BodyG, BodyGSpan, BodyP, BodyPSpan, H1, H5 } from '../../styles/stylesTypography.js';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import IconTooltip from '../../assets/icons/icons-tooltip.svg'

const Indique = () => {
    const [coupon, setCoupon] = useState(" ");
    const [isLoading, setIsLoading] = useState(true);
    const [modalBlocked, setModalBlocked] = useState(false);

    const userPlan = JSON.parse(localStorage.getItem("@userinfo"))
    const getUSerPlan = userPlan.contrato.agro_plan_config.nome_plano

    const RedirectPlan = () => {
        window.location.href = '/planos'; 
    };

    useEffect(() => {
        if (getUSerPlan === "TESTE GRÁTIS") {
            setModalBlocked(true)
        }
    },[getUSerPlan]) 

    useEffect(() => {
        const userInfoData = localStorage.getItem("@userinfo");

        if (userInfoData) {
            const userInfo = JSON.parse(userInfoData);
            const company = userInfo.contrato.company.nome_fantasia;
            const myArray = company.split(" ");
            const newCoupon = `AMIGO@${myArray.join('')}`.toUpperCase();
            setCoupon(newCoupon);


            const processarDados = async () => {
                if (localStorage.getItem('IndiqueReload') !== 'false') {
                    localStorage.setItem('IndiqueReload', 'true');
                }

                if (getUSerPlan !== "TESTE GRÁTIS") {
                    try {
                        const requestData = {
                            agro_subsidiary_id: userInfo.agro_subsidiary_id,
                            nome_cupom: newCoupon,
                        };

                        const response = await api.post(`/criar-cupom`, requestData);

                        if (response.data.message === null) {
                            setCoupon(response.data.data)
                            localStorage.setItem("@cupom", "true");
                        }
                    } catch (error) {
                        console.error(
                            "Erro ao criar o cupom:",
                            error.response ? error.response.data : error.message
                        );
                    } finally {
                        setIsLoading(false)
                    }
                } else {}
            };

            processarDados();

        } else {
            setIsLoading(false)
        }

        if (localStorage.getItem('IndiqueReload') === 'true') {
            localStorage.setItem('IndiqueReload', 'false');
            window.location.reload();
        } 
    }, []);

    const tooltipDiscont = (
        <div style={{ textAlign: 'left', padding: '10px', maxWidth: '300px', }}>
            <ul style={{ margin: 0, padding: '0 0 0 20px', listStyleType: 'disc' }}>
                <li>
                    <strong>Assinantes Mensal (Basic, Light ou Pro):</strong> Ganhe 20% de desconto nas 12 primeiras mensalidades para cada licença comprada com seu cupom.
                </li>

                <li>
                    <strong>Assinantes Anual (Basic, Light ou Pro):</strong> Ganhe 20% de desconto para cada licença anual comprada com seu cupom.
                </li>

                <li>
                    <strong>Clientes Pré-pago:</strong> Ganhe 20% de desconto na primeira compra com seu cupom.
                </li>
            </ul>
        </div>
    );

    const tooltipContent = (
        <div style={{ textAlign: 'left', padding: '10px', maxWidth: '300px' }}>
            <ul style={{ margin: 0, padding: '0 0 0 20px', listStyleType: 'disc' }}>
                <li>
                    <strong>Assinantes Mensal (Basic, Light ou Pro):</strong> Ganha uma mensalidade grátis para cada novo cliente que comprar com seu cupom exclusivo.
                </li>
                <li>
                    <strong>Assinantes Anual (Basic, Light ou Pro):</strong> Ganha 30 dias a mais no vencimento do seu plano para cada novo cliente que comprar com seu cupom exclusivo.
                </li>
                <li>
                    <strong>Clientes Pré-pago:</strong> Ganha R$ 60 de créditos pré-pago para cada novo cliente que comprar com seu cupom exclusivo (equivalente a 13 créditos de receituários).
                </li>
            </ul>
        </div>
    );

    const copyToCupom = () => {
        navigator.clipboard.writeText(coupon)

        store.addNotification({
            title: "Cupom copiado com sucesso!",
            message: `O cupom ${coupon} foi copiado para a área de transferência.`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        })
    };

    return (
        <S.Container>
            <S.Wrapper>
                <HeaderV2 />

                <S.ContainerCupom>
                    <S.MainImage><img src={MainImage} alt="Main" /></S.MainImage>
                    <S.CupomTitle>
                        <H1>Agora você faz parte do Programa Indique & Ganhe AgroReceita!</H1>
                    </S.CupomTitle>

                    <S.CupomText>
                        <BodyG>
                            Você já pode começar a compartilhar seu <BodyGSpan>cupom exclusivo</BodyGSpan> e ganhar <BodyGSpan>benefícios incríveis.</BodyGSpan> Vamos juntos fazer o agronegócio crescer ainda mais!
                        </BodyG>
                    </S.CupomText>

                    <S.ViewCupom  onClick={copyToCupom}>
                        <Auxiliar>Seu cupom exclusivo é</Auxiliar>

                        {isLoading ? <H5>Carregando cupom...</H5> : <H5>{coupon}</H5>}
                    </S.ViewCupom>
                </S.ContainerCupom>

                <S.CupomSecondSection>
                    <BodyGSpan>Compartilhe agora mesmo e comece a ganhar benefícios! Quanto mais você indica, mais você ganha!</BodyGSpan>

                    <S.Curiosity>
                        <BodyG>
                            Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? AgroReceita mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro para você emitir Receituários com segurança. Use meu cupom <BodyGSpan color='#FFFFFF'>{coupon}</BodyGSpan> e garanta 20% de desconto! 😉
                        </BodyG>

                        <S.ButtonSection>
                            {buttonData.map((data) => (
                                <button key={data.id} onClick={() => data.onClick(coupon)}>
                                    <img src={data.src} alt={data.alt} />
                                    {data.text}
                                </button>
                            ))}
                        </S.ButtonSection>
                    </S.Curiosity>

                    <S.Benefits>
                        <S.TableWrapper>
                            <S.LineInfo>
                                <BodyPSpan>Seu indicado compra</BodyPSpan>

                                <BodyPSpan>Seu indicado ganha</BodyPSpan>

                                <BodyPSpan>Você ganha</BodyPSpan>
                            </S.LineInfo>

                            <S.LineDescription>
                                <BodyP>Qualquer plano usando <br/>seu cupom</BodyP>

                                <S.DescriptionandTooltip>
                                    <BodyP>20% de desconto</BodyP>

                                    <div>
                                        <Tippy
                                            content={tooltipDiscont}
                                            animation="fade"
                                            delay={[100, 100]}
                                            arrow={true}
                                            theme="light"
                                            placement="bottom"
                                        >

                                            <img src={IconTooltip} alt="Tooltip Icon" />
                                        </Tippy>
                                    </div>
                                </S.DescriptionandTooltip>

                                <S.DescriptionandTooltip>
                                    <BodyP>1 mensalidade grátis ou <br /> R$ 60 em créditos</BodyP>

                                    <div>
                                        <Tippy
                                            content={tooltipContent}
                                            animation="fade"
                                            delay={[100, 100]}
                                            arrow={true}
                                            theme="light"
                                            placement="bottom"
                                        >

                                            <img src={IconTooltip} alt="Tooltip Icon" />
                                        </Tippy>
                                    </div>
                                </S.DescriptionandTooltip>
                            </S.LineDescription>
                        </S.TableWrapper>
                        <p>
                            Tem alguma dúvida ou precisa de ajuda? Acesse as <a href="https://agroreceita.com.br/regras-indique-e-ganhe/">Regras do Programa</a> ou fale com nossa equipe para ajudar você a aproveitar ao máximo o nosso programa. <a href='https://wa.me/5515981657739?text=Ol%C3%A1,%20queria%20saber%20mais%20sobre%20o%20programa%20Indique%20e%20Ganhe.
' target='blank_'>Entre em contato</a> conosco a qualquer momento!
                        </p>
                    </S.Benefits>
                </S.CupomSecondSection>
            </S.Wrapper>

            <S.ContainerModalBlocked>
                <ModalGeneric
                    show={modalBlocked}
                    onHide={false}
                >
                    <S.ModalBlockedTitle>
                        Essa é uma função exclusiva para clientes
                    </S.ModalBlockedTitle>

                    <S.ModalBlockedDescription>
                        Confira nossos planos e veja todas as funções disponiveis em cada um deles.
                    </S.ModalBlockedDescription>

                    <div className="button-finish" style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center',}}>
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => RedirectPlan()}
                            loading={false}
                        />
                    </div>
                </ModalGeneric> 
            </S.ContainerModalBlocked>
        </S.Container >
    );
};

export default withRouter(Indique);