import { store } from 'react-notifications-component';

import whatsapp from "../../assets/icons/icon-Whatsapp.png";
import copy from "../../assets/icons/icon-copy.png";
import email from "../../assets/icons/icon-Email.png";

const handleWhatsapp = (coupon) => {
    const text = encodeURIComponent(`Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? AgroReceita mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro para você emitir Receituários com segurança. \n\nUse meu cupom *${coupon}* e garanta *20% de desconto!* 😉`);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
        ? `https://api.whatsapp.com/send?text=${text}`
        : `https://web.whatsapp.com/send?text=${text}`;

    window.open(whatsappUrl, "_blank");
};

const handleEmail = (coupon) => {
    const subject = encodeURIComponent("Cupom exclusivo! Fique em dia com a legislação hoje!");
    const body = encodeURIComponent(`Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? AgroReceita mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro para você emitir Receituários com segurança. \n\nUse meu cupom ${coupon} e garanta 20% de desconto! 😉`);
    const mailtoLink = `mailto:?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
};


export const buttonData = [
    {
        id: 1,
        src: copy,
        alt: "Ícone de copiar",
        text: "COPIAR",
        onClick: (coupon) => {
            const textToCopy = `Sabia que o M.A.P.A. atualiza cerca de 70 bulas de defensivos por semana? AgroReceita mantém tudo sempre atualizado de acordo com as legislações de cada estado brasileiro para você emitir Receituários com segurança. \n\nUse meu cupom ${coupon} e garanta 20% de desconto! 😉`;
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    store.addNotification({
                        title: "Texto copiado com sucesso!",
                        message: `O texto foi copiado para a área de transferência.`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                })
        }
    },

    {
        id: 2,
        src: whatsapp,
        alt: "Ícone do Whatsapp",
        text: "WHATSAPP",
        onClick: handleWhatsapp,
    },

    {
        id: 3,
        src: email,
        alt: "Ícone do Email",
        text: "EMAIL",
        onClick: handleEmail,
    },
];

