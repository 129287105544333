import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 2rem;

    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 0.125rem solid #F3F3F5;

    @media (max-width: 790px) {
        padding: 42px 0 1rem 3rem;
    }

    @media (max-width: 479px) {
        padding: 42px 0 1rem 4rem;
        
        gap: 0;
    }
`;

export const ContainerSubscribeAndUser = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    @media (max-width: 375px) {
        margin-right: 2rem;
        flex-direction: column;
    }

    @media (max-width: 360px) {
        gap: 0.75rem;
    }
`;

export const SubscribeButton = styled.button`
    all: unset;

    width: 6.25rem;
    height: 1.25rem;
    padding: 0.25rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 2rem;
    background-color: #589BFF;

    > p {
        cursor: pointer;

        color: #FFFFFF;
        font-weight: 800;
        font-size: 0.5625rem;
        letter-spacing: 0.1rem;
        line-height: 0.84375rem;
        font-family: "Lato", Sans-serif;
    }

    :hover {
        opacity: 0.7;
    }
`;

export const ManagePlansButton = styled.button`
    all: unset;

    width: auto;
    height: 1.25rem;
    padding: 0.25rem 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    border-radius: 2rem;
    background-color: #E9ECF0;

    > p {
        cursor: pointer;
    }

    :hover {
        opacity: 0.7;
    }
`;

export const UserName = styled.div`
    width: auto;
    height: auto;

    > h4 {
        @media (max-width: 790px) {
            text-align: center;

            font-size: 1rem;
            line-height: 1rem;
        }

    }
`;

export const ContainerNotificationAndProfile = styled.div`
    width: auto;
    height: auto;

    gap: 0.5rem;
    display: flex;
    align-items: center;
`;

export const ContainerNotification = styled.button`
    all: unset;

    position: relative;

    width: 3rem;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: center;

    > a > img {
        width: 1.25rem;
        height: 1.25rem;

        cursor: pointer;
        border-radius: 10rem;
    }

    &::after {
        top: 0.9375rem;
        right: 0.9375rem;
        position: absolute;
        
        width: 0.3125rem;
        height: 0.3125rem;
        
        content: '';
        display: ${({ hasNotification }) => (hasNotification ? 'block' : 'none')}; 
        
        border-radius: 50%;
        background-color: red;
    }

    @media (max-width: 320px) {
        width: 2rem;
        height: 3rem;
    }
`;

export const Profile = styled.div`
    position: relative;

    width: auto;
    height: auto;
    padding: 0.3rem 0.5rem 0.3rem 0.3rem;

    gap: 0.5rem;
    display: flex;
    align-items: center;

    cursor: pointer;
    border-radius: 10rem;
    background-color: #F3F3F5;

    > img {
        width: 2rem;
        height: 2rem;

        border-radius: 10rem;
    }

    > p {
        cursor: pointer;
    }
`;

export const NameProfile = styled.div`
    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10rem;
    background-color: #21262F;

    >span{
        cursor: pointer;

        color: #FFFFFF;
    }
`

export const ButtonProfile = styled.button`
    all: unset;

    display: flex;
    align-items: center;

    cursor: pointer;
`;

export const Accordion = styled.div`
    top: 315%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    z-index: 10;
    width: 10rem;  

    display: flex;
    flex-direction: column;

    background: #fff;
    border-radius: 0.5rem; 
    border: 0.0625rem solid #ddd;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.1);  

    a{
        padding: 0.5rem 1rem;  

        display: flex;
        justify-content: center;

        color: #333;
        cursor: pointer;
        font-size: 0.625rem; 

        &:hover {
          background-color: #f5f5f5;
        }
    }

    @media (max-width: 769px) {
        right: -0.9125rem; 
    }
`;

export const AccordionItem = styled.label`
    padding: 0.5rem 1rem;  

    display: flex;
    justify-content: center;

    color: #333;
    cursor: pointer;
    font-size: 0.625rem;   

    &:hover {
        background-color: #f5f5f5;
    }
`;