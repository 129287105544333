import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import { login, logout, clearSession } from "../../services/auth";
import Logo from "../../assets/logo_hd.svg";
import Button from "../../components/button/button_v2";
import ModalSenha from "../../components/modal/modalSenha";
import ModalExit from "../../components/modal/modalExit";
import ModalText2 from "../../components/modal/modalText";
import ModalLoginErrado from "../../components/modal/modal_login_errado";
import ModalGeneric from "../../components/modal/modalGeneric";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import IconLinkedin from "../../assets/icons/linkedin";
import LinkedIn, { LinkedInCallback } from 'react-linkedin-login-oauth2';


import {
    LoginWrapper,
    Col2,
    ContainerLogo,
    ContainerForm,
    Line,
    ContainerInputsForm,
} from "./styles";

import Input from "../../components/input/input_v2";
export const USER_ATIVO = "@user_ativo";
export const USER_VALIDADE = "@validade";

export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_NOME = '@agroreceita_google_nome';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function base64UrlDecode(string) {
    string = string.replace(/-/g, '+').replace(/_/g, '/');
    
    const padding = string.length % 4;
    if (padding) {
      string += '='.repeat(4 - padding);
    }

    let datas = atob(string);
    datas = datas.split(";");
    
    return datas;
}

function handleCredentialResponse(credential) {
    console.log('entrou')
    if (credential) {
        var profile = parseJwt(credential);  
        var google_user = { profile };
    
        var profileUserGoogle = {
            id: profile.sub,
            name: profile.name,
            email: profile.email
        }
    
        var exit = localStorage.getItem('@agroreceita_exit');
        
        localStorage.setItem('@useraccesskey', Buffer.from(profileUserGoogle.id, 'utf8').toString('base64'));
        localStorage.setItem("@agroreceita_google_email", profile.email);
        localStorage.setItem("@agroreceita_google_nome", profileUserGoogle.name);
        localStorage.setItem("@agroreceita_google_user", JSON.stringify(google_user));
        localStorage.setItem("@agroreceita_google_user_profile", JSON.stringify(profileUserGoogle));
    }
}

const LoginPage = ({ ...props }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalExit, setShowModalExit] = useState(false);
    const [title, setTitle] = useState('');
    const [subtitle, setSubTitle] = useState('');
    const [msg, setMsg] = useState('');
    const [showModalText, setShowModalText] = useState(false);
    const [showModalText2, setShowModalText2] = useState(false);
    const [email, setEmail] = useState(false);
    const [loadingLoginSocial, setLoadingLoginSocial] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailGoogle, setEmailGoogle] = useState(localStorage.getItem('@agroreceita_google_email'));
    const [nomeGoogle, setNomeGoogle] = useState(localStorage.getItem('@agroreceita_google_nome'));
    const [emailLinkedin, setEmailLinkedin] = useState(localStorage.getItem('@agroreceita_linkedin_email'));
    const [nomeLinkedin, setNomeLinkedin] = useState('');
    const [idLinkedin, setIdLinkedin] = useState('');
    const [erroEmail, setErroEmail] = useState(false);
    const [erroEmailMsg, setErroEmailMsg] = useState('');
    const [erroSenha, setErroSenha] = useState(false);
    const [erroSenhaMsg, setErroSenhaMsg] = useState('');
    const [erroCad, setErroCad] = useState(false);

    const [credentialLoginHash, setCredentialLoginHash] = useState('')


    useEffect(() => {
        localStorage.removeItem(GOOGLE_EMAIL);
        localStorage.removeItem(GOOGLE_USER);
        localStorage.removeItem(GOOGLE_USER_PROFILE);

        let user = localStorage.getItem(USER_ATIVO);
        if (user == "1") {
            clearSession();
        } else {
            logout();
        }

        checkSocialLogin();

        loginQuery();

        setTimeout(() => {

            let aTags = document.getElementsByTagName("span");
            let searchText = "Login";

            for (let i = 0; i < aTags.length; i++) {
                if (aTags[i].textContent == searchText) {
                    aTags[i].innerText = 'Continuar com Google';
                    break;
                }
            }
            setInterval(() => {
                let aTags = document.getElementsByTagName("span");
                let searchText = "Login";

                for (let i = 0; i < aTags.length; i++) {
                    if (aTags[i].textContent == searchText) {
                        aTags[i].innerText = 'Continuar com Google';
                        break;
                    }
                }

                let codeLinkedin = localStorage.getItem('@codelinkedin');

                if(codeLinkedin){
                    localStorage.removeItem('@codelinkedin');
                 }
            }, 500);
        }, 300);

    }, []);

    useEffect(() => {
        if (emailGoogle) {
            handleSocialSignIn(emailGoogle, 'google');
        }
    }, [emailGoogle]);

    useEffect(() => {
        if (emailLinkedin) {
            handleSocialSignIn(emailLinkedin, 'linkedin');
        }
    }, [emailLinkedin]);

    const loginQuery = async () => {
        let queryLogin = decodeURIComponent(new URLSearchParams(window.location.search).get('q'));
        let loginInfos = queryLogin ? base64UrlDecode(queryLogin) : null;
        let queryCredential = decodeURIComponent(new URLSearchParams(window.location.search).get('credential'));

        let queryIOS = decodeURIComponent(new URLSearchParams(window.location.search).get('ios'));

        if (queryIOS && queryIOS !== 'null') {
            localStorage.setItem('@agroreceita:ios', queryIOS);
        } else {
            localStorage.setItem('@agroreceita:ios', '');
        }

        if (queryCredential && queryCredential !== 'null') {
            setCredentialLoginHash(queryCredential);
            handleCredentialResponse(queryCredential);
        } else {
            if (loginInfos && loginInfos[1] && loginInfos[1] !== 'null' && loginInfos[0] && loginInfos[0] !== 'null') {
                changeUsername(loginInfos[0]);
                changePassword(loginInfos[1]);
    
                handleSignIn(loginInfos[0], loginInfos[1], true);
        
            } else {
                if(loginInfos[2] && (loginInfos[2] == 'true' || loginInfos[2] == true)){
                    handleSocialSignIn(loginInfos[0], loginInfos[3]);
                }
            }
        }


    }

    const checkSocialLogin = () => {
        const email = localStorage.getItem('@agroreceita_google_email');
        const emailLin = localStorage.getItem('@agroreceita_linkedin_email');
        const nomeLin = localStorage.getItem("@agroreceita_linkedin_nome_user");
        const idLin = localStorage.getItem("@agroreceita_linkedin_id");

        if (email && (!emailGoogle || emailGoogle === '')) {
            setEmailGoogle(email);
        } else {
            if (emailLin && (!emailLinkedin || emailLinkedin === '')) {
                setEmailLinkedin(emailLin);
                setNomeLinkedin(nomeLin);
                localStorage.setItem('@useraccesskey', Buffer.from(idLin, 'utf8').toString('base64'));
                setIdLinkedin(idLin);
            }else{
                setEmailGoogle('');
                setEmailLinkedin('');
            }

        }

        setTimeout(() => {
            checkSocialLogin();
        }, 500);
    }

    const { linkedInLogin } = useLinkedIn({
        clientId: '77u1m9fh81cpbq',
        redirectUri: process.env.REACT_APP_API_HOST_LINKEDIN, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: ['r_liteprofile', 'r_emailaddress'],
        onSuccess: (code) => {
            alert('sucesso');
        },
        onError: (error) => {
            handleSocialSignIn(localStorage.getItem('@agroreceita_linkedin_email'));
            
        },
    });

    const handleSocialSignIn = async (email, tipo) => {
        try {

            if (!loadingLoginSocial) {
                setLoadingLoginSocial(true);

                try {
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username: email, password: null, loginSocial: 1, type: tipo }, { headers })
                        .then(response => {
                            if (response) {
                                login(response.data.data);
                                let isLogged = localStorage.getItem('@logged');

                                if ((isLogged == false || isLogged == 'false') && (localStorage.getItem('@open') == false || localStorage.getItem('@open') == 'false')) {
                                    localStorage.setItem("@open", "true");
                                    window.location = '/apresentacao';
                                } else {
                                    window.location = "/";
                                }
                                setLoading(false);
                            }
                        }).catch(error => {
                            if (error.response.data.errors === 'Nenhum registro encontrado.') {
                                setErroCad(true);
                                window.location = "/cadastro";
                            } else {
                                setEmailGoogle('');
                                setLoadingLoginSocial(false);
                                setErroCad(true);
                                localStorage.removeItem('@agroreceita_google_email');
                                localStorage.removeItem('@agroreceita_google_user');
                                localStorage.removeItem('@agroreceita_google_user_profile');
                            }

                        });
                } catch (err) {
                    if (err.response.data.errors === 'Nenhum registro encontrado.') {
                        setErroCad(true);
                    } else {
                        setEmailGoogle('');
                        setLoadingLoginSocial(false);
                        // setError('Houve um problema com o login, verifique suas credenciais.');
                        setErroCad(true);
                        localStorage.removeItem('@agroreceita_google_email');
                        localStorage.removeItem('@agroreceita_google_user');
                        localStorage.removeItem('@agroreceita_google_user_profile');
                    }
                }
            }
        } catch (err) {
            if (err.response.data.errors === 'Nenhum registro encontrado.') {
                setErroCad(true);
            } else {
                setEmailGoogle('');
                setLoadingLoginSocial(false);
                setErroCad(true);
                localStorage.removeItem('@agroreceita_google_email');
                localStorage.removeItem('@agroreceita_google_user');
                localStorage.removeItem('@agroreceita_google_user_profile');
            }
        }
    };



    const modalClose = () => {
        setShowModal(false);
    }
    const modalExitClose = () => {
        setShowModalExit(false);
    }
    const modalCloseText = () => {
        setShowModalText(false);
    }
    const modalCloseText2 = () => {
        setShowModalText2(false);
    }
    const modalCloseText3 = () => {
        setError('');
    }


    const updateResposta = async (resposta, email) => {
        if (resposta == 10) {
            setShowModal(false);
            setShowModalText2(true);
            setEmail(email);
        }
    }
    const updateRespostaExit = async (resposta) => {
        // console.log(resposta);
    }

    const updateTextOk = async (resposta) => {
        setShowModalText(false);
    }

    const updateTextOk2 = async (resposta) => {
        setShowModalText(false);
        window.location.href = '/login';
    }
    const updateTextOk3 = async (resposta) => {
        setError('');

        if (resposta == 0) {
            // window.location.href = "https://agroreceita.com.br/#planos";
            window.location.href = "/cadastro";
        } else {
        }
    }

    const getQueryParams = () => {
        const utm_id = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_id'));
        const utm_source = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_source'));
        const utm_medium = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_medium'));
        const utm_campaign = decodeURIComponent(new URLSearchParams(window.location.search).get('utm_campaign'));
        const button = decodeURIComponent(new URLSearchParams(window.location.search).get('button'));

        let queryString = '';

        if (utm_id && utm_id !== 'null') {
            queryString = `${queryString}&utm_id=${utm_id}`;
        }

        if (utm_source && utm_source !== 'null') {
            queryString = `${queryString}&utm_source=${utm_source}`;
        }

        if (utm_medium && utm_medium !== 'null') {
            queryString = `${queryString}&utm_medium=${utm_medium}`;
        }

        if (utm_campaign && utm_campaign !== 'null') {
            queryString = `${queryString}&utm_campaign=${utm_campaign}`;
        }

        if (button && button !== 'null') {
            queryString = `${queryString}&button=${button}`;
        }

        if (queryString) {
            localStorage.setItem('@utm_params', queryString);
        }
    }

        useEffect(() => {
            getQueryParams();
        }, [])

    const handleSignIn = (queryUsername, queryPassword, isQuery, type) => {
        let usernameNew = '';
        let passwordNew = '';
        if (isQuery) {
            usernameNew = queryUsername;
            passwordNew = queryPassword;
        }else{
            usernameNew = username;
            passwordNew = password;
        }
        if (!usernameNew || !passwordNew) {
            if (!usernameNew) {
                setErroEmail(true);
                setErroEmailMsg('Informe seu email para continuar');
            }

            if (!passwordNew) {
                setErroSenha(true);
                setErroSenhaMsg('Informe sua senha para continuar');
            }
        } else {
            try {
                setLoading(true);

                const headers = {
                    'apiKey': process.env.REACT_APP_API_KEY
                }
                axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username: usernameNew, password: passwordNew, type: 'email'}, { headers })
                .then(response => {
                    
                    if (response) {
                        login(response.data.data);
                        localStorage.setItem('@useraccesskey', Buffer.from(passwordNew, 'utf8').toString('base64'))
                        setLoading(false);

                        let user = JSON.parse(localStorage.getItem("@userinfo"));
                        let isLogged = localStorage.getItem('@logged');

                        if ((isLogged == false || isLogged == 'false') && (localStorage.getItem('@open') == false || localStorage.getItem('@open') == 'false')) {
                            localStorage.setItem("@open", "true");
                            window.location = '/apresentacao';
                        } else {
                            window.location = "/";
                        }

                        setLoading(false);
                    }
                }).catch(error => {
                    if (error.response.data.errors === 'Contrato vencido!') {
                        setError('Contrato vencido! Entre em contato com nosso suporte.');
                        setLoading(false);

                        } else {
                            if (error.response.data.errors === 'Nenhum registro encontrado.') {
                                setErroCad(true);
                                setLoading(false);
    
                                setErroEmail(true);
                                setErroEmailMsg('Verifique suas credenciais!');
    
                                setErroSenha(true);
                                setErroSenhaMsg('Verifique suas credenciais!');
                            } else {
                                setErroCad(true);
                                setLoading(false);
    
                                setErroEmail(true);
                                setErroEmailMsg('Verifique suas credenciais!');
    
                                setErroSenha(true);
                                setErroSenhaMsg('Verifique suas credenciais!');
                            }
                        }

                });
            } catch (err) {
                if (err.response.data.errors === 'Nenhum registro encontrado.') {
                    setErroCad(true);
                } else {
                    setError('Houve um problema com o login, verifique suas credenciais.');
                    setLoading(false);
                }
            }
        }
    };


    const pressKey = (e) => {

        if (e === 'Enter') {
            if (username && password) {
                handleSignIn();
            }
        }
    }


    const changeUsername = (e) => {
        setUsername(e);
        setErroEmail(false);
    }

    const changePassword = (e) => {
        setPassword(e);
        setErroSenha(false);
    }

    useEffect(() => {
        if (localStorage.getItem('@loginReload') === 'true') { 
            localStorage.setItem('@loginReload', 'false'); 
            window.location.reload();
        }
    }, []);

    return (
        <>
            <LoginWrapper>

                <Col2>
                    <ContainerLogo>
                        <div className='logo'>
                            <a href="https://www.agroreceita.com.br" target="_blank">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                        <label className="title">Olá, seja bem vindo(a)!</label>
                        <label className="subtitle">Preencha os campos para entrar</label>
                    </ContainerLogo>
                </Col2>

                <Col2>
                    <ContainerForm>
                        <div className="btnGoogle">
                            <div id="g_id_onload"
                                data-client_id={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                                data-context="signin"
                                data-ux_mode="redirect"
                                data-login_uri={`${process.env.REACT_APP_API_HOST}/loginsocial`}
                                data-auto_prompt="false">
                            </div>

                            <div class="g_id_signin"
                                data-type="standard"
                                data-shape="rectangular"
                                data-theme="outline"
                                data-text="signin_with"
                                data-size="large"
                                data-locale="pt-BR"
                                data-logo_alignment="left">
                            </div>
                        </div>

                        <div className="btnLinkedin"
                            onClick={linkedInLogin}
                            alt="Iniciar sessão com o Linkedin"
                        >
                            <IconLinkedin width="25px" heigh="25px" /><span className="iniciar">Iniciar sessão com o Linkedin</span>
                        </div>

                        <Line>
                            <hr />
                            <span>OU</span>
                        </Line>

                        <ContainerInputsForm>
                            <Input
                                id='email'
                                label="E-mail"
                                name="email"
                                type="email"
                                onChange={e => changeUsername(e)}
                                marginBottom='0px'
                                onKeyDown={(e) => pressKey(e)}
                                value={username}
                                erro={erroEmail}
                                messageErro={erroEmailMsg}
                            />

                            <Input
                                name="password"
                                label="Senha"
                                type='password'
                                onChange={e => changePassword(e)}
                                marginBottom='0px'
                                onKeyDown={(e) => pressKey(e)}
                                id='senha'
                                value={password}
                                erro={erroSenha}
                                messageErro={erroSenhaMsg}
                            />

                            <div className='recover'>
                                <label onClick={() => setShowModal(true)}> Esqueci minha senha </label>
                            </div>

                            <div className='box-end'>
                                <label className="link" onClick={() => window.location = '/cadastro'}> Não possuo cadastro </label>

                                <div className="button-login" style={{ width: '112px' }}>
                                    <Button
                                        width='100%'
                                        height='50px'
                                        value='Entrar'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => handleSignIn()}
                                        loading={loading}
                                    />
                                </div>

                            </div>

                        </ContainerInputsForm>

                    </ContainerForm>
                </Col2>

                {error ? (
                    <ModalLoginErrado
                        show={error}
                        onHide={() => modalCloseText3()}
                        title={error == 'Contrato vencido! Entre em contato com nosso suporte.' ? 'Contrato vencido!' : 'ACESSO NEGADO'}
                        text={error == 'Contrato vencido! Entre em contato com nosso suporte.' ? 'Entre em contato com nosso suporte.' : error}
                        update={(e) => updateTextOk3(e)}
                    />
                ) : (null)}


                {erroCad && (
                    <ModalGeneric
                        onHide={() => setErroCad(false)}
                        show={erroCad} 
                        size='md'
                    >
                        <h3 style={{ textAlign:'center' }}>Registro não encontrado!</h3>
                        <p style={{ textAlign:'center' }}>Verifique seu email e senha e tente novamente!</p>
                        <br></br>


                        <div className="button-login" style={{ width: '236px', margin: 'auto', marginBottom: '16px'}}>
                            <Button
                                width='100%'
                                height='50px'
                                value='TENTAR NOVAMENTE'
                                outline={true}
                                disabled={false}
                                onClick={() => setErroCad(false)}
                                loading={loading}
                            />
                        </div>
                        
                        <div className="button-login" style={{ width: '236px', margin: 'auto'}}>
                            <Button
                                width='100%'
                                height='50px'
                                value='CRIAR NOVA CONTA'
                                outline={false}
                                disabled={false}
                                onClick={() => credentialLoginHash ? window.location = `/cadastro?credential=${credentialLoginHash}` : emailLinkedin ? window.location = `/cadastro?username=${encodeURIComponent(emailLinkedin)}&nome=${encodeURIComponent(nomeLinkedin)}&id=${encodeURIComponent(idLinkedin)}` : window.location = '/cadastro'}
                                loading={loading}
                            />
                        </div>

                    </ModalGeneric>
                )}

                <ModalSenha
                    show={showModal}
                    onHide={() => modalClose()}
                    resposta=''
                    update={(resposta, email) => updateResposta(resposta, email)}
                    title='Esqueceu a senha?'
                    text='Digite o e-mail ou usuário cadastrado para alterá-la.'
                ></ModalSenha>

                <ModalExit
                    show={showModalExit}
                    onHide={() => modalExitClose()}
                    resposta=''
                    update={(e) => updateRespostaExit(e)}
                    title={title}
                    text={msg}
                    subtitle={subtitle}
                ></ModalExit>

                {email ? (
                    <ModalText2
                        show={showModalText2}
                        senha={true}
                        onHide={() => modalCloseText2()}
                        btnOk={true}
                        text={`${email} para que você `}
                        update={(e) => updateTextOk2(e)}
                    />
                ) : (null)}
            </LoginWrapper>
        </>
    );
};

export default withRouter(LoginPage);