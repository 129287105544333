import React, { useState } from "react";
import { withRouter } from 'react-router-dom';

import Button from "../../button/button_v2";
import ModalGeneric from '../../modal/modalGeneric';

import IconPlus from "../../../assets/icons/icon-plus-black.svg";
import IconClose from "../../../assets/icons/icon-close-red.svg";

import { theme } from "../../../styles/theme";
import { BodyG, BodyM, H2, H5, Minimo } from "../../../styles/stylesTypography";

import * as S from "./styles";

const FrequentQuestions = ({ history,...props }) => {
    const [activeQuestions, setActiveQuestions] = useState([]);

    const toggleAnswer = (questionIndex) => {
        setActiveQuestions((prevState) => {
            if (prevState.includes(questionIndex)) {
                return prevState.filter((index) => index !== questionIndex);
            }

            return [...prevState, questionIndex];
        });
    };

    const [modalBlocked, setModalBlocked] = useState(false);

    const handleRedirect = () => {
        if (localStorage.getItem("@userinfo")) {
            const userPlan = JSON.parse(localStorage.getItem("@userinfo"));
            const getUSerPlan = userPlan?.contrato?.agro_plan_config?.nome_plano;

            if (getUSerPlan === "TESTE GRÁTIS") {
                setModalBlocked(true);
            } else {
                localStorage.setItem('IndiqueReload', 'true');
                window.location.href = '/cupom-indique-e-ganhe';
            }
        } else {
            localStorage.setItem('@loginReload', 'true');
            window.location.href = '/cupom-indique-e-ganhe';
        }
    };

    const RedirectPlan = () => {
        window.location.href = '/planos'; 
    }

    const questions = [
        {
            question: "Como recebo meu cupom exclusivo?",
            answer:
                "Basta clicar no botão “GERAR MEU CUPOM” para aceitar os termos do programa e seu cupom exclusivo será gerado automaticamente e estará disponível para você compartilhar.",
        },
        {
            question: "Quantas pessoas posso indicar?",
            answer:
                "Você pode indicar quantas pessoas desejar. Cada indicação bem-sucedida lhe renderá benefícios.",
        },
        {
            question: "Quando recebo meus benefícios?",
            answer:
                "Os benefícios são creditados na sua conta após a validação do indicado no programa.",
        },
        {
            question: "Posso participar se estiver em um plano gratuito?",
            answer:
                "Não. Este programa é exclusivo para clientes AgroReceita.",
        },
    ];

    
    return (
        <S.Container>
            <S.Wrapper>
                <H2 color={theme.colors.white.Branco}>Perguntas Frequentes</H2>

                <S.ContainerQuestionsAndButton>
                    {questions.map((item, index) => (
                        <S.CardQuestion
                            key={index}
                            onClick={() => toggleAnswer(index)}
                        >
                            <S.Question>
                                <H5 fontWeight={700}>{item.question}</H5>

                                <img
                                    src={
                                        activeQuestions.includes(index)
                                            ? IconClose
                                            : IconPlus
                                    }
                                    alt="Ícone de Mais ou de fechar"
                                />
                            </S.Question>

                            <S.Answer
                                className={
                                    activeQuestions.includes(index) ? "Active" : "Hidden"
                                }
                            >
                                <BodyM fontWeight={400} color={theme.colors.gray.Cinza_6}>{item.answer}</BodyM>
                            </S.Answer>
                        </S.CardQuestion>
                    ))}

                    <S.ContainerButtonAndTerms>
                        <Button
                            width="206px"
                            height="54.5px"
                            value="GERAR MEU CUPOM"
                            outline={false}
                            disabled={false}
                            loading={false}
                            onClick={handleRedirect}
                        />

                        <Minimo color={theme.colors.white.Branco}>Ao gerar seu cupom, você aceita as
                            <a href="https://agroreceita.com.br/regras-indique-e-ganhe">
                                &nbsp; regras do programa
                            </a>
                        </Minimo>
                    </S.ContainerButtonAndTerms>
                </S.ContainerQuestionsAndButton>
            </S.Wrapper>

            <ModalGeneric
                show={modalBlocked}
                onHide={() => setModalBlocked(false)}
                >
                <S.ContainerModalBlocked>
                    <H5>Essa é uma função exclusiva para clientes</H5>

                    <BodyG>Confira nossos planos e veja todas as funções disponiveis em cada um deles.</BodyG>

                    <div className="button-finish" style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center',}}>
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => RedirectPlan()}
                            loading={false}
                        />
                    </div>
                </S.ContainerModalBlocked>
            </ModalGeneric> 
        </S.Container>
    );
}

export default withRouter(FrequentQuestions);