import React, { useState, useEffect } from 'react';
import Button from "../button/button_v2.js";
import * as S from './styles.js';
import logo from "../../assets/logo-verde-fundo-branco.png"
import check from "../../assets/check-verde.png"
import x from "../../assets/X-cinza.png"
import { withRouter } from "react-router-dom"
import api from '../../services/api.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min.js';

function PlanTable({ ...props }) {

    const [isMobile, setIsMobile] = useState(false);
    const [positionMiddle, setPositionMiddle] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const [grid, setGrid] = useState([]);
    const [showPrepaid, setShowPrepaid] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Ler o parâmetro 'prepaid' da URL
        const searchParams = new URLSearchParams(location.search);
        const isPrepaid = searchParams.get('prepaid') === 'true';

        // Ativar o pré-pago se o parâmetro for true
        if (isPrepaid) {
            setShowPrepaid(true);
        }
    }, [location.search]);

    const getUtmUrl = (planoId) => {
        try {
            const currentUrl = new URL(window.location.href);
            const utmParams = new URLSearchParams(currentUrl.search);

            // Verifica se já tem UTM
            const hasUtm = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some(param => utmParams.has(param));

            // Se não tiver UTM, adiciona a UTM padrão
            // if (!hasUtm) {
            //     utmParams.set('utm_term', 'NONE');
            //     utmParams.set('utm_source', 'sistema');
            //     utmParams.set('utm_medium', 'box-planos');
            //     utmParams.set('utm_campaign', 'NONE');
            // }

            // Verifica se já tem o cupom, se não, adiciona
            // if (!utmParams.has('cupom') && (planoId === 7 || planoId === 4 || planoId === 5)) {
            //     utmParams.set('cupom', 'CONSUMIDOR30');
            // }

            if (hasUtm) {
                return `/compra?${planoId}&${utmParams.toString()}`;
                
            } else if ( planoId === 7 || planoId === 4 || planoId === 5) {
                return `/compra?${planoId}`;
            }
        } catch (error) {
            // Fallback para URL padrão em caso de erro
            if (planoId === 7 || planoId === 4 || planoId === 5) {
                return `/compra?${planoId}`;
            }
        }
    };

    const assinar = (planoId) => {
        localStorage.setItem('@loginReload', 'true');
        const url = getUtmUrl(planoId);
        props.history.push(url);
    };

    const getPlanos = async () => {
        try {
            const response = await api.get(`/planos/funcoes`);
            const data = response.data.data;
            setPlanos(data.planos);
            setHeaders(data.funcoes);
            setPermissoes(data.permissoes);
            setGrid(data.grid);
        } catch (error) {
            console.error("Erro ao buscar dados da API:", error);
        }
    };

    useEffect(() => {
        getPlanos();
    }, []);

            const SubscribeBasic = () => {
                if (props.location.pathname === '/receituario-agronomico-2025') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='garantir desconto' margin='15px' height='36px' width='186px' onClick={() => assinar(7)} />
                    )
                }
            }

            const subscribeLight = () => {
                if (props.location.pathname === '/receituario-agronomico-2025') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='garantir desconto' margin='15px' height='36px' width='186px' onClick={() => assinar(4)} />
                    )
                }
            }

            const subscribePro = () => {
                if (props.location.pathname === '/receituario-agronomico-2025') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='garantir desconto' margin='15px' height='36px' width='186px' onClick={() => assinar(5)} />
                    )
                }
            }

            const SubscribeMobile = () => {
                if (props.location.pathname === '/receituario-agronomico-2025') {
                    return (
                        <a href='#teste-gratis'><Button value='Teste Grátis' margin='15px' height='36px' width='186px' /></a>
                    )
                } else {
                    return (
                        <Button value='assinar' margin='15px' height='36px' width='186px' onClick={() => assinar(7)} />
                    )
                }
            }

            const PriceContainerDesktop = () => {
                return (

                    <S.PriceRow isPrepaid={false}>
                        <S.TableCell>
                            <S.AdditionalText>*Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!</S.AdditionalText>
                        </S.TableCell>
                        {grid
                            .filter(g => g.plan_id !== 6)
                            .map((g, index) => (
                                <S.TableCell key={index}>
                                    <S.PriceWithButtonContainer>
                                        {/* <S.OldPrice>
                                            <span className="currency-symbol">R$</span>
                                            <span>{g.vlr}</span>
                                            <span className="cents">,{g.cents}</span>
                                        </S.OldPrice> */}

                                        {/* <S.Price>
                                         <span className="currency-symbol">R$</span>
                                            <span>
                                                {g.plan_id === 7 ? "55" :
                                                 g.plan_id === 4 ? "118" :
                                                 g.plan_id === 5 ? "146" : ""} 
                                            </span>
                                            <span className="cents">
                                                {g.plan_id === 7 ? ",93*" :
                                                 g.plan_id === 4 ? ",93*" :
                                                 g.plan_id === 5 ? ",93*" : ""}
                                            </span>
                                        </S.Price> */}

                                        <S.Price>
                                            <span className="currency-symbol">R$</span>
                                            
                                            <span>{g.vlr}</span>

                                            <span className="cents">,{g.cents}</span>
                                        </S.Price>

                                        <S.PriceDescription>mensais/usuário</S.PriceDescription>

                                        {g.plan_id === 4 || g.plan_id === 5 ? (
                                            <S.PriceDescription>10% OFF NO PLANO ANUAL</S.PriceDescription>
                                        ) : (
                                            <S.PriceDescription><br /></S.PriceDescription>
                                        )}

                                        {g.plan_id === 7 ? (
                                            SubscribeBasic()
                                        ) : g.plan_id === 4 ? (
                                            subscribeLight()
                                        ) : g.plan_id === 5 ? (
                                            subscribePro()
                                        ) : null}
                                    </S.PriceWithButtonContainer>
                                </S.TableCell>
                            ))}
                    </S.PriceRow>

                )
            }

            const PriceContainerMobile = () => {
                return (
                    <S.MobilePriceContainer>
                        {grid
                            .filter(g => g.plan_id !== 6)
                            .map((g, index) => (
                                <S.MobilePriceBox key={index} positionMiddle={index === 1}>
                                    <S.MobilePlans>
                                        {g.plan_id === 7 ? "Plano Basic" :
                                            g.plan_id === 4 ? "Plano Light" :
                                                g.plan_id === 5 ? "Plano Pro" : ""}
                                    </S.MobilePlans>
                                        {/* <S.OldPrice>
                                            <span className="currency-symbol">R$</span>
                                            <span>{g.vlr}</span>
                                            <span className="cents">,{g.cents}</span>
                                        </S.OldPrice> */}

                                        {/* <S.Price>
                                         <span className="currency-symbol">R$</span>
                                            <span>
                                                {g.plan_id === 7 ? "55" :
                                                 g.plan_id === 4 ? "118" :
                                                 g.plan_id === 5 ? "146" : ""} 
                                            </span>
                                            <span className="cents">
                                                {g.plan_id === 7 ? ",93*" :
                                                 g.plan_id === 4 ? ",93*" :
                                                 g.plan_id === 5 ? ",93*" : ""}
                                            </span>
                                        </S.Price> */}

                                    <S.Price isMobile={true}>
                                         R$ <span>{g.vlr},{g.cents}*</span>
                                     </S.Price>

                                    
                                    <S.PriceDescription isMobile={true}>mensais/usuário</S.PriceDescription>
                                    {g.plan_id === 4 || g.plan_id === 5 ? (
                                        <S.PriceDescription>10% OFF NO PLANO ANUAL</S.PriceDescription>
                                    ) : (
                                        <S.PriceDescription><br /></S.PriceDescription>
                                    )}
                                </S.MobilePriceBox>
                            ))}
                    </S.MobilePriceContainer>
                )
            }

            const TablePrepaid = () => {
                // Filtra o plano pré-pago (id 6)
                const prepaidPlan = planos.find(plano => plano.id === 6);
                const prepaidGrid = grid.find(g => g.plan_id === 6);

                if (!prepaidPlan || !prepaidGrid) {
                    return <p>Carregando dados do plano pré-pago...</p>;
                }

                return (
                    <S.MainContainer className='plans'>
                        <S.Title className='plans'>Tome melhores decisões com o AgroReceita</S.Title>
                        <S.Subtitle className='plans'>Quanto mais usuários ou receitas, maior o desconto.</S.Subtitle>

                        <S.ContainerTable isPrepaid={true}>
                            <S.Table>
                                <S.TableHead>
                                    <S.TableRow>
                                        <S.TableHeader isPrepaid={true}><img src={logo} alt='Icone' width="20" className='icon' /></S.TableHeader>
                                        <S.TableHeader isPrepaid={true}>Pré-Pago</S.TableHeader>
                                    </S.TableRow>
                                </S.TableHead>
                                <S.TableBody>
                                    {headers.length > 0 && permissoes.length > 0 ? (
                                        headers.map((header, index) => {
                                            // Encontra a permissão correspondente para o plano pré-pago (id 6)
                                            const permissao = permissoes.find(
                                                per => per.agro_plan_function_id === header.id && per.plan_id === 6
                                            );

                                            // Verifica se é o item "Emissão de Receitas"
                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                return (
                                                    <S.TableRow key={index}>
                                                        <S.TableCell isPrepaid={true}>{header.descricao}</S.TableCell>
                                                        <S.TableCell style={{ fontSize: '14px' }}>
                                                            Compre créditos que não expiram nunca
                                                        </S.TableCell>
                                                    </S.TableRow>
                                                );
                                            } else {
                                                return (
                                                    <S.TableRow key={index}>
                                                        <S.TableCell isPrepaid={true}>{header.descricao}</S.TableCell>
                                                        <S.TableCell isPrepaid={true}>
                                                            {permissao?.status === 1 ? (
                                                                <img isPrepaid={true} src={check} alt="Certo" width="15%" />
                                                            ) : (
                                                                <img isPrepaid={true} src={x} alt="Errado" width="15%" />
                                                            )}
                                                        </S.TableCell>
                                                    </S.TableRow>
                                                );
                                            }
                                        })
                                    ) : (
                                        <p>Carregando funcionalidades...</p>
                                    )}

                                    <S.SupportRow isPrepaid={true}>
                                        <S.TableCell>
                                            <S.SupportTitle isPrepaid={true}>Suporte</S.SupportTitle>
                                        </S.TableCell>
                                        <S.TableCell>
                                            <S.SupportPlanName isPrepaid={true}>Suporte Plano Pré-Pago</S.SupportPlanName>
                                            <S.SupportList>
                                                <S.SupportItem>WhatsApp</S.SupportItem>
                                                <S.SupportItem>E-mail</S.SupportItem>
                                                <S.SupportItem>Central de Ajuda</S.SupportItem>
                                            </S.SupportList>
                                        </S.TableCell>
                                    </S.SupportRow>

                                    <S.PriceRow isPrepaid={true} className='pre-paid-price'>
                                        <S.TableCell>
                                            <S.AdditionalText isPrepaid={true}>
                                                *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui</a>. Quanto mais você contratar, maior é o seu desconto!
                                            </S.AdditionalText>
                                        </S.TableCell>
                                        <S.TableCell className='price-prepaid'>
                                            <S.PriceWithButtonContainer isPrepaid={true}>
                                                <S.Price isPrepaid={true}>
                                                    <span className="currency-symbol">R$</span>
                                                    <span>{prepaidGrid.vlr}</span>
                                                    <span className="cents">,{prepaidGrid.cents}*</span>
                                                </S.Price>
                                                <S.PriceDescription>por Receituário</S.PriceDescription>
                                                <S.PriceDescription>DESCONTO PROGRESSIVO</S.PriceDescription>
                                                <Button
                                                    value='Comprar Créditos'
                                                    margin='15px'
                                                    height='36px'
                                                    width='186px'
                                                    onClick={() => assinar(6)}
                                                />
                                            </S.PriceWithButtonContainer>
                                        </S.TableCell>
                                    </S.PriceRow>
                                </S.TableBody>
                            </S.Table>
                            <S.PrePaidPlanContainer isPrepaid={true}>
                                Conheça os <button onClick={() => setShowPrepaid(false)}><a href='#'>Planos de Assinatura</a></button> e emita seus Receituários Agronômicos com tranquilidade.
                            </S.PrePaidPlanContainer>
                        </S.ContainerTable>
                    </S.MainContainer>
                );
            };


            const isPlans = () => {
                if (showPrepaid) {
                    return (
                        <>
                            {TablePrepaid()}
                        </>
                    )
                }
                if (props.location.pathname === '/planos') {
                    return (
                        <S.MainContainer className='plans'>

                            <S.Title className='plans'>Tome melhores decisões com o AgroReceita</S.Title>
                            <S.Subtitle className='plans'>Quanto mais usuários ou receitas, maior o desconto.</S.Subtitle>

                            <S.ContainerTable isPrepaid={false}>
                                <S.Table>
                                    <S.TableHead>
                                        <S.TableRow>
                                            <S.TableHeader><img src={logo} alt='Icone' width="20" className='icon' /></S.TableHeader>
                                            <S.TableHeader>Plano Basic</S.TableHeader>
                                            <S.TableHeader>Plano Light</S.TableHeader>
                                            <S.TableHeader>Plano Pro</S.TableHeader>
                                        </S.TableRow>
                                    </S.TableHead>
                                    <S.TableBody>
                                        {headers.length > 0 && planos.length > 0 && permissoes.length > 0 ? (
                                            headers.map((header, index) => (
                                                <S.TableRow key={index}>
                                                    <S.TableCell isPrepaid={false}>{header.descricao}</S.TableCell>
                                                    {planos
                                                        .filter(plano => plano.id !== 6) // Filtra para remover o plano pré-pago (id 6)
                                                        .map((plano) => {
                                                            // Encontra a permissão correspondente
                                                            const permissao = permissoes.find(
                                                                per => per.agro_plan_function_id === header.id && per.plan_id === plano.id
                                                            );

                                                            // Verifica se é o item "Emissão de Receitas"
                                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.descricao || "Não disponível"}
                                                                    </S.TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.status === 1 ? (
                                                                            <img isPrepaid={false} src={check} alt="Certo" width="30" />
                                                                        ) : (
                                                                            <img isPrepaid={false} src={x} alt="Errado" width="30" />
                                                                        )}
                                                                    </S.TableCell>
                                                                );
                                                            }
                                                        })}
                                                </S.TableRow>
                                            ))
                                        ) : (
                                            <></>
                                        )}

                                        <S.SupportRow className='support-row'>
                                            <S.TableCell className='support-row'>
                                                <S.SupportTitle isPrepaid={false}>Suporte</S.SupportTitle>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br />Basic</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br />Light</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br />Pro</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Suporte Prioritário</S.SupportItem>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                        </S.SupportRow>

                                        {PriceContainerDesktop()}

                                    </S.TableBody>
                                </S.Table>
                                <S.PrePaidPlanContainer>
                                    Conheça o <button onClick={() => setShowPrepaid(true)}><a href='#'>Plano Pré-Pago</a></button> e compre créditos para utilizar quando quiser.
                                </S.PrePaidPlanContainer>
                            </S.ContainerTable>


                            {PriceContainerMobile()}

                            <S.ButtonBelowPrices>
                                {SubscribeMobile()}
                            </S.ButtonBelowPrices>

                            <S.MobileAdditionalTextContainer>
                                <S.MobileAdditionalText>
                                    *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui.</a>. Quanto mais você contratar, maior é o seu desconto!
                                </S.MobileAdditionalText>
                            </S.MobileAdditionalTextContainer>


                        </S.MainContainer>
                    );
                } else {
                    return (
                        <S.MainContainer className='receituario'>

                            <S.Title>Tome melhores decisões com o AgroReceita</S.Title>
                            <S.Subtitle>Quanto mais usuários ou receitas, maior o desconto.</S.Subtitle>

                            <S.ContainerTable className='receituario' isPrepaid={false}>
                                <S.Table>
                                    <S.TableHead>
                                        <S.TableRow>
                                            <S.TableHeader><img src={logo} alt='Icone' width="30" className='icon' /></S.TableHeader>
                                            <S.TableHeader>Plano Basic</S.TableHeader>
                                            <S.TableHeader>Plano Light</S.TableHeader>
                                            <S.TableHeader>Plano Pro</S.TableHeader>
                                        </S.TableRow>
                                    </S.TableHead>
                                    <S.TableBody>
                                        {headers.length > 0 && planos.length > 0 && permissoes.length > 0 ? (
                                            headers.map((header, index) => (
                                                <S.TableRow key={index}>
                                                    <S.TableCell isPrepaid={false}>{header.descricao}</S.TableCell>
                                                    {planos
                                                        .filter(plano => plano.id !== 6) // Filtra para remover o plano pré-pago (id 6)
                                                        .map((plano) => {
                                                            // Encontra a permissão correspondente
                                                            const permissao = permissoes.find(
                                                                per => per.agro_plan_function_id === header.id && per.plan_id === plano.id
                                                            );

                                                            // Verifica se é o item "Emissão de Receitas"
                                                            if (header.id === 1) { // Substitua 1 pelo ID correto da função "Emissão de Receitas"
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.descricao || "Não disponível"}
                                                                    </S.TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <S.TableCell key={plano.id} isPrepaid={false}>
                                                                        {permissao?.status === 1 ? (
                                                                            <img isPrepaid={false} src={check} alt="Certo" width="30" />
                                                                        ) : (
                                                                            <img isPrepaid={false} src={x} alt="Errado" width="30" />
                                                                        )}
                                                                    </S.TableCell>
                                                                );
                                                            }
                                                        })}
                                                </S.TableRow>
                                            ))
                                        ) : (
                                            <p>Carregando dados...</p>
                                        )}

                                        <S.SupportRow className='support-row'>
                                            <S.TableCell className='support-row'>
                                                <S.SupportTitle isPrepaid={false}>Suporte</S.SupportTitle>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br />Basic</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br />Light</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                            <S.TableCell className='support-row'>
                                                <S.SupportPlanName>Suporte Plano<br /> Pro</S.SupportPlanName>
                                                <S.SupportList>
                                                    <S.SupportItem>Suporte Prioritário</S.SupportItem>
                                                    <S.SupportItem>Telefone</S.SupportItem>
                                                    <S.SupportItem>WhatsApp</S.SupportItem>
                                                    <S.SupportItem>E-mail</S.SupportItem>
                                                    <S.SupportItem>Central de Ajuda</S.SupportItem>
                                                </S.SupportList>
                                            </S.TableCell>
                                        </S.SupportRow>

                                        {PriceContainerDesktop()}

                                    </S.TableBody>
                                </S.Table>
                                <S.PrePaidPlanContainer>
                                    Conheça o <button onClick={() => setShowPrepaid(true)}><a href='#'>Plano Pré-Pago</a></button> e compre créditos para utilizar quando quiser.
                                </S.PrePaidPlanContainer>
                            </S.ContainerTable>


                            {PriceContainerMobile()}

                            <S.ButtonBelowPrices>
                                {SubscribeMobile()}
                            </S.ButtonBelowPrices>

                            <S.MobileAdditionalTextContainer>
                                <S.MobileAdditionalText>
                                    *Para mais usuários ou receitas, <a onClick={() => window.location = '/simulacaoplano/7?'}>simule aqui.</a> Quanto mais você contratar, maior é o seu desconto!
                                </S.MobileAdditionalText>
                            </S.MobileAdditionalTextContainer>


                        </S.MainContainer>
                    );
                }
            }

            return (
                <>
                    {isPlans()}
                </>
            )

        }

        export default withRouter(PlanTable);