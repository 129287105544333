import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";

import { login } from '../../services/auth';

import Logo from '../../assets/logo-agroreceita-branco.svg';
import Email from '../../assets/imagem-email-sucesso.svg';

import { theme } from '../../styles/theme';
import { H1, BodyG, BodyGSpan, TaglineM } from '../../styles/stylesTypography';

import * as S from './style';

const Success = ({ ...props }) => {
    const [isUpload, setIsUpload] = useState(false);

    const userInfo = localStorage.getItem('@userinfo')
    const userObject = JSON.parse(userInfo);

    useEffect(() => {
        if (window.location.search) {
            if (window.location.search === '?update=true') {
                setIsUpload(true);
            }
        }
        localStorage.removeItem('@store');
    }, [])

    const redirectHome = () => {
        let password = localStorage.getItem('@useraccesskey');
        let user = JSON.parse(localStorage.getItem('@userinfo'))

        const username = user.username;

        if (password) {
            const psswd = Buffer.from(password, 'base64').toString('utf8');

            if (username && psswd) {
                try {
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username, password: psswd }, { headers })
                        .then(response => {

                            if (response) {
                                login(response.data.data);
                                localStorage.setItem('@useraccesskey', Buffer.from(psswd, 'utf8').toString('base64'))
                                window.location = "/";
                            }
                        }).catch(error => {
                            window.location = "/";
                        });
                } catch (err) {
                    window.location = "/";
                }
            } else {
                window.location = '/';
            }
        } else {
            const googleProfile = JSON.parse(localStorage.getItem('@agroreceita_google_user_profile'));

            const id = googleProfile.id;

            if (id) {
                try {
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username, password: id }, { headers })
                        .then(response => {
                            if (response) {
                                login(response.data.data);
                                localStorage.setItem('@useraccesskey', Buffer.from(id, 'utf8').toString('base64'))
                                window.location = "/";
                            }
                        }).catch(error => {
                            window.location = "/";
                        });
                } catch (err) {
                    window.location = "/";
                }
            } else {
                window.location = '/';
            }
        }
    }

    return (
        <S.ContainerSuccess>
            <S.ContainerLeft>
                <img src={Logo} alt="" />

                <S.ContainerTextWithEmail>
                    {isUpload ? (
                        <H1 color={theme.colors.white.Branco}>Atualização efetuada<br /> com sucesso!</H1>
                    ) : (
                        <H1 color={theme.colors.white.Branco}>Compra efetuada<br /> com sucesso!</H1>
                    )}

                    <S.TextWithEmail>
                        {isUpload ? (
                            <BodyG color={theme.colors.gray.Cinza_9}>
                                Enviamos um e-mail de confirmação da atualização para&nbsp;
                                <BodyGSpan color={theme.colors.white.Branco}>{userObject.username}</BodyGSpan>. Caso não encontre
                                nosso e-mail, confira sua caixa de spam.
                            </BodyG>
                        ) : (
                            <BodyG color={theme.colors.gray.Cinza_9}> 
                                Enviamos um e-mail de confirmação de compra para&nbsp;
                                <BodyGSpan color={theme.colors.white.Branco}>{userObject.username}</BodyGSpan>. Caso não encontre
                                nosso e-mail, confira sua caixa de spam.
                            </BodyG>
                        )}
                    </S.TextWithEmail>
                </S.ContainerTextWithEmail>

                <S.ButtonHome onClick={() => redirectHome()}>
                    <TaglineM color={theme.colors.white.Branco}>Voltar para o sistema</TaglineM>
                </S.ButtonHome>
            </S.ContainerLeft>

            <img src={Email} alt="Imagem envelope de email" />
        </S.ContainerSuccess>
    );
}

export default withRouter(Success);
