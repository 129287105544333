import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: 100vh;
    padding: 0 2rem;

    gap: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #292E38;

    >img{
        width: auto;
        height: 15rem;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      >img{
         display: none;
      }
    }
`;

export const Description = styled.div` 
    width: 100%;
    height: auto;
    max-width: 26.25rem;

    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    >img{
        width: auto;
        height: 2rem;
    }

    @media (max-width: 768px) {
      max-width: 100%;

      align-items: center;
    }
`;

export const ContainerTextWithEmail = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      >h1{
        margin-bottom: 1rem;
        text-align: center;
      }

      p{
        text-align: center;
      }
    }

    @media (max-width: 478px) {
      >h1{
        font-size: 1.5rem;
        line-height: 2rem;
      }

      p {
       font-size: 1rem;
      }
    }
`;

export const TextWithEmail = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    margin-top: 1rem;

    >p:nth-of-type(1){
      min-width: 12.5rem;
    }

    span {
      margin: 0; 
      
      color: #FFFFFF;
      font-weight: 700; 
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 478px) {
      align-items: center;
      flex-direction: column;

      p, span {
        font-size: 1rem;
      }
    }
`;