import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Cancel from "../../../assets/cancel";
import Button from "../../button/button_v2";
import Input from "../../input/input_v2";
import Select from "../../select/select_v2";
import api from "../../../services/api";
import { Container } from "react-grid-system";
import { FormContainer, Loading } from "./style";
import { store } from "react-notifications-component";



const CompraStep0 = ({ ...props }) => {
    const { update, next, numUsers, numDoc, numPrescipt, valorTotalProps, valorPlanoProps, cupom} = props;
    
    const [disabled, setDisabled] = useState(true);
    const [disabledNumUsers, setDisabledNumUsers] = useState(true);
    const [disabledPrescript, setDisabledPrescript] = useState(true);
    const [disabledDoc, setDisabledDoc] = useState(true);
    const [gridId, setGridId] = useState('');
    const [numUsersId, setNumUsersId] = useState(numUsers || '');
    const [numDocuments, setNumDocuments] = useState(numDoc || '');
    const [numPrescriptions, setNumPrescriptions] = useState(numPrescipt || '');
    const [valorTotal, setValorTotal] = useState(valorTotalProps || '');
    const [valorPlano, setValorPlano] = useState(valorPlanoProps || '');
    const [plano, setPlano] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState([]);
    const [planos, setPlanos] = useState([]);

    const [renovacao, setRenovacao] = useState({value: '2', label: 'MENSAL'});
    const [renewTypes, setRenewTypes] = useState([]);

    const [isValid, setIsValid] = useState(false);

    const [isPrePago, setIsPrePago] = useState(false);

    const [loading, setLoading] = useState(true);

    const [showInfo, setShowInfo] = useState(false);

    const nextStep = async e => {

        if (isPrePago) {
            if (numPrescriptions >= 40) {
                let planId = plano[0].id;
                update(gridId, planId, numUsersId, numDocuments, numPrescriptions, planoSelecionado, valorTotal, valorPlano);
                next();
            } else {
                store.addNotification({
                    title: "Atenção!",
                    message: `Para o plano pré pago é necessário selecionar pelo menos 40 receitas`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            let planId = plano[0].id;
            update(gridId, planId, numUsersId, numDocuments, numPrescriptions, planoSelecionado, valorTotal, valorPlano);
            next();
        }
    }

    const getPlanoAtivo = () => {
        let user = JSON.parse(localStorage.getItem('@userinfo'));

        if (user) {
            getPlanos(user.contrato.agro_plan_config.agro_plan_id, user.contrato.agro_plan_config.nivel);
        } else {
            getPlanos(0, 0);
        }
        
        let paramId = props.locProp.match.params.id;
        let planoId = props.locProp.location.search;
        const dadosCupom = decodeURIComponent(new URLSearchParams(window.location.search).get('cupom'));

        if (paramId === undefined && planoId === '') {
            //Aqui o usuário está em /simulacaoplano/id
            getPlano(paramId, 0, 1, 0);
        } else {
            if (paramId === undefined) {
                paramId = planoId.slice(1);
            }

            if (paramId.includes('cupom')){            
                cupom(dadosCupom);
            }
            

            paramId = paramId.split('&')[0];
            

            if (user !== null && user) {
                getPlano(paramId, user.contrato.agro_plan_config.agro_plan_id, user.contrato.agro_plan_config.nivel, 0);
            } else {
                getPlano(paramId, 0, 1, 0);
            }
        }
    }

    const formatPlanos = (planos, idPlanoAtivo, nivelPlanoAtivo) => {  
        let formatPlano = planos.map(p => {

            if (p.id !== idPlanoAtivo && p.display === 1 && p.nivel > nivelPlanoAtivo) {
                const format = {
                    value: p.id,
                    label: p.plan_name,
                    users: p.users_limit,
                    documents: p.documents_limit,
                    prescriptions: p.prescriptions_limit,
                    type: p.plan_type_id,
                    is_pre_pago: p.is_pre_pago
                };
                return format;
            }
        });

        let returnFormat = [];

        formatPlano.map((fp) => {
            if (fp && fp !== undefined) {
                returnFormat = [ ...returnFormat, fp ];
            }
        });

        return returnFormat;
    }

    const getPlanos = async (idPlanoAtivo, nivelPlanoAtivo) => {
        try {
            const response =  await api.get(`/planos`);
            let planos = response.data.data;
            let format = formatPlanos(planos, idPlanoAtivo, nivelPlanoAtivo );

            setPlanos(format);

            if (format.length > 0) {
                //Se houver planos possíveis de atualização, é válido.
                setIsValid(true);
            }
        } catch (error) {

        }
    }
    
    const formatRenewPlan = (plano) => {
        let isMensal = false;
        let isSemestral = false;
        let isAnual = false;

        plano[0].plans_grid.map(p => {
            if (p.renew_type_id === 2) {
                isMensal = true;
            } else if (p.renew_type_id === 3) {
                isSemestral = true;
            } else if (p.renew_type_id === 4) {
                isAnual = true;
            }
        });


        let options = [];

        if (isMensal) {
            options = [ ...options, {value: '2', label: 'MENSAL'}];
        }
        if (isSemestral) {
            options = [ ...options, {value: '3', label: 'SEMESTRAL'}];
        }
        if (isAnual) {
            options = [ ...options, {value: '4', label: 'ANUAL'}];
        }

        return options;

    }

    const getPlano = (id, idPlanoAtivo, nivelPlanoAtivo, qtdUsers) => {
        if (id !== planoSelecionado) {

            if (id && id !== 0) {
                api.get(`/planos/${id}`).then((response)=>{
                    let planoResponse = response.data.data;

                    if (planoResponse[0].is_pre_pago === 1) {
                        setIsPrePago(true);
                    } else {
                        setIsPrePago(false);
                    }

                    if (planoResponse[0].nivel <= nivelPlanoAtivo) {
                        setIsValid(false);
                    } else {
                        setIsValid(true);
    
                        setPlano(planoResponse);
            
                        let p = formatPlanos(planoResponse, idPlanoAtivo, nivelPlanoAtivo);
                        let options = formatRenewPlan(planoResponse)
    
                        setPlanoSelecionado(p[0]);
                        setRenewTypes(options);
                        
                        if (!qtdUsers) {
                            planoResponse[0].plans_grid.map((grid) => {
            
                                if (grid.qtd_users === 1 && `${grid.renew_type_id}` === renovacao.value) {
                                    setValorPlano(grid.vlr_unit);
                                }
                            })
                        } else {
            
                            planoResponse[0].plans_grid.map(p => {
                                if(p.vlr_minimo == 0 && p.qtd_users == qtdUsers){
                                    setValorPlano(p.vlr_unit);
                                }else{
                                    if(p.vlr_minimo == 1 && p.qtd_users <= qtdUsers){
                                        setValorPlano(p.vlr_unit);
                                    }
                                }
                            })
                        }
                    }

                    calculaValorTotal(planoResponse, parseInt(qtdUsers), renovacao);

                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                }).catch((error)=>{
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
            } else {
                setIsValid(true);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }
        }
    } 

    const calculaValorTotal = (plan, vlr, renew) => {

        if (plan.length > 0) {
            const p = plan[0];
            if (p.plans_grid.length > 0) {
    
                let valorPlano = 0;
                let valorTotal = 0;
                let grid = 0;
    
                let find = false;
    
                if (p.plan_type_id === 2 || p.plan_type_id === 3 || p.plan_type_id === 4) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
                                if (grid.renew_type_id === null || grid.renew_type_id === 1) {
                                    if (vlr <= parseInt(grid.qtd_users)) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;
                                        find = true;
                                        
                                    } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;

                                        find = true;
                                    } else if (parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;

                                        find = true;
                                    }
                                } else {
                                    if (parseInt(renew.value) === parseInt(grid.renew_type_id)) {
                                        if (vlr <= parseInt(grid.qtd_users)) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                            
                                        } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                        } else if (parseInt(grid.vlr_minimo) === 1) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                        }
                                    }
                                }
                            }
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);

                        update(grid, p.value, numUsersId, numDocuments, numPrescriptions, p, valorTotal, valorPlano);

                    }
                    
                } else if (p.plan_type_id === 5) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
                                if (grid.renew_type_id === null) {
                                    if (vlr <= parseInt(grid.qtd_users)) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;
                                        find = true;
                                        
                                    } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;
                                        find = true;
                                    } else if (parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;
                                        find = true;
                                    }
                                } else { 
                                    if (parseInt(renew.value) === parseInt(grid.renew_type_id)) {
                                        if (vlr == parseInt(grid.qtd_users)) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                            
                                        } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                        }
                                    }
                                }
                            }
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
            
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);

                        update(grid, p.value, vlr, numDocuments, numPrescriptions, p, valorTotal, valorPlano);
                    }
                } else if (p.plan_type_id === 6) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
                                if (grid.renew_type_id === null || grid.renew_type_id === 1) {
                                    if (vlr <= parseInt(grid.qtd_users)) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;
                                        find = true;
                                        
                                    } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;

                                        find = true;
                                    } else if (parseInt(grid.vlr_minimo) === 1) {
                                        valorPlano = grid.vlr_unit;
                                        setGridId(grid.id);
                                        grid = grid.id;

                                        find = true;
                                    }
                                } else {
                                    if (parseInt(renew.value) === parseInt(grid.renew_type_id)) {
                                        if (vlr <= parseInt(grid.qtd_users)) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                            
                                        } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                        } else if (parseInt(grid.vlr_minimo) === 1) {
                                            valorPlano = grid.vlr_unit;
                                            setGridId(grid.id);
                                            grid = grid.id;
                                            find = true;
                                        }
                                    }
                                }
                            }
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);

                        update(grid, p.value, numUsersId, numDocuments, numPrescriptions, p, valorTotal, valorPlano);
                    }
                }
            } else {
                setValorPlano('0,00');
                setValorTotal('0,00');
                setGridId(0);
            }
        }
    }

    const validateNumUsuarios = valor => {
        setNumUsersId(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor), renovacao);
    }

    const validateNumPrescript = valor => {

        if (valor === 'ilimitado' || valor === '') {

        } else {
            setNumPrescriptions(parseInt(valor));
            calculaValorTotal(plano, parseInt(valor), renovacao);
    
            if (planoSelecionado.type === 4) {
                setNumDocuments(parseInt(valor));
            }
        }

    }

    const validateNumDocument = valor => {
        setNumDocuments(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor), renovacao);

        if (planoSelecionado.type === 4) {
            setNumPrescriptions(parseInt(valor));
        }
    }

    const validatePlano = planoSelect => {  
        
        if (planoSelect.value !== planoSelecionado.value) {
            let user = JSON.parse(localStorage.getItem('@userinfo'));
    
            if (user) {
                let idPlanoAtivo = user.contrato.agro_plan_config.agro_plan_id;
                let nivelPlanoAtivo = user.contrato.agro_plan_config.nivel;
    
                setPlanoSelecionado(planoSelect); 
                getPlano(planoSelect.value, idPlanoAtivo, nivelPlanoAtivo, 1);
        
                if (planoSelect.users === 1) {
                    validateNumUsuarios(1)
                    setDisabledNumUsers(true);
                } else {
                    validateNumUsuarios(1)
                    setDisabledNumUsers(false);
                }
        
                if (planoSelect.documents !== null) {
                    validateNumDocument(planoSelect.documents);
                    setDisabledDoc(true)
                } else {
                    validateNumDocument('');
                    setDisabledDoc(false);
                }
                
                if (planoSelect.prescriptions !== null) {
                    validateNumPrescript(planoSelect.prescriptions);
                    setDisabledPrescript(true)
                } else {
                    validateNumPrescript('');
                    setDisabledPrescript(false);
                }
            } else {
                setPlano(planoSelect);
                setPlanoSelecionado(planoSelect); 
                getPlano(planoSelect.value, 0, 0, 1);
        
                if (planoSelect.users === 1) {
                    validateNumUsuarios(1)
                    setDisabledNumUsers(true);
                } else {
                    validateNumUsuarios(1)
                    setDisabledNumUsers(false);
                }
        
                if (planoSelect.documents !== null) {
                    validateNumDocument(planoSelect.documents);
                    setDisabledDoc(true)
                } else {
                    validateNumDocument("");
                    setDisabledDoc(false);
                }
                
                if (planoSelect.prescriptions !== null) {
                    validateNumPrescript(planoSelect.prescriptions);
                    setDisabledPrescript(true)
                } else {
                    validateNumPrescript('');
                    setDisabledPrescript(false);
                }
            }
        } else {
            if (planoSelect.users === 1) {
                validateNumUsuarios(1)
                setDisabledNumUsers(true);
            } else {
                validateNumUsuarios(1)
                setDisabledNumUsers(false);
            }
    
            if (planoSelect.documents !== null) {
                validateNumDocument(planoSelect.documents);
                setDisabledDoc(true)
            } else {
                validateNumDocument("");
                setDisabledDoc(false);
            }
            
            if (planoSelect.prescriptions !== null) {
                validateNumPrescript(planoSelect.prescriptions);
                setDisabledPrescript(true)
            } else {
                validateNumPrescript('');
                setDisabledPrescript(false);
            }
        }
    }

    const validateRenew = (renewType) => {
        if (renewType.value !== renovacao.value) {
            setRenovacao(renewType);
            calculaValorTotal(plano, numUsersId, renewType);
        }
    }

    useEffect(() => {
        getPlanoAtivo();
    },[])

    useEffect(() => {
        if (plano.length > 0) {
            if (plano[0].plan_type_id === 2 || plano[0].plan_type_id === 3 || plano[0].plan_type_id === 4) {
                setNumUsersId(1);
                if (numDocuments || numPrescriptions) {
                    setDisabled(false);
                } else {
                    setDisabled(true);
                }
            } else {
                if(numUsersId >= 1){
                    setDisabled(false);
                } else {
                    setDisabled(true);
                }
            }
        }

        if (props.isOff === true) {

            props.update({
                gridId, 
                planId: planoSelecionado ? planoSelecionado.value : '', 
                numUsersId, 
                numDocuments, 
                numPrescriptions, 
                planoSelecionado, 
                valorTotal, 
                valorPlano
            })
        }
    },[numUsersId, numDocuments, numPrescriptions, plano, renovacao, planoSelecionado, valorPlano, valorTotal, gridId])


    const userPlan = localStorage.getItem('@plan_name');

    useEffect(() => {
        if (userPlan === 'PLANO PRO') {
            setShowInfo(true);
        }
    },[userPlan, showInfo])

    return (
        <>
            {loading ? (
                <div style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Loading />
                </div>
            ) : (
                <>
                    {planos.length > 0 || showInfo === true ? (
                        <FormContainer>
                            <Container style={{minHeight: '370px'}}>

                                {isValid === false || showInfo === true ? (
                                    <>
                                        <h4
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                marginTop: '50px'
                                            }}
                                        >
                                            Não há possibilidade de upgrade para o plano selecionado
                                        </h4>
        
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
        
                                            <Cancel />  
        
                                        </div>
                                    </>
                                ) : (
                                    <form>
                                        <Select 
                                            label="Escolha seu plano"
                                            options={planos}
                                            value={planoSelecionado ? planoSelecionado.value : planoSelecionado}
                                            onChange={(e) => validatePlano(e)}
                                            disabled={false}
                                            className="select"
                                            id='plano'
                                        />

                                        {plano.length > 0 && (
                                            <>
                                                {renewTypes.length > 0 ? (
                                                    <>
                                                        <Select 
                                                            label="Escolha o tipo de renovação"
                                                            options={renewTypes}
                                                            value={renovacao.value || renovacao}
                                                            onChange={(e) => validateRenew(e)}
                                                            disabled={false}
                                                            className="select"
                                                            id='renew'
                                                            selectUniqueOption={true}
                                                        />
                                                    </>
                                                ) : (null)}
                                            </>
                                        )}
        
                                        {planoSelecionado.type === 5 ? (
                                            <Input
                                                name="numUsuarios"
                                                label="Número de usuários"
                                                type="number"
                                                min="1" 
                                                id='num_usuarios'
                                                value={numUsersId}
                                                disabled={disabledNumUsers}
                                                onChange={(e) => validateNumUsuarios(e)}
                                            />
                                        ) : (
                                            <>
                                                {planoSelecionado.type === 4 ? (
                                                    <>
                                                        {planoSelecionado.users !== null ? (
                                                            <Input
                                                                name="numUsuarios"
                                                                label="Número de usuários"
                                                                type="number"
                                                                min="1" 
                                                                id='num_usuarios'
                                                                value={numUsersId}
                                                                disabled={disabledNumUsers}
                                                                onChange={(e) => validateNumUsuarios(e)}
                                                            />
                                                        ) : (null)}
        
                                                        <Input
                                                            name="numreceita"
                                                            label="Número de receitas"
                                                            type="number"
                                                            min="1" 
                                                            id='num_usuarios'
                                                            value={numPrescriptions}
                                                            disabled={disabledPrescript}
                                                            onChange={(e) => validateNumPrescript(e)}
                                                        />
                                                        
                                                        <Input
                                                            name="numdocumentos"
                                                            label="Número de documentos"
                                                            type="number"
                                                            min="1" 
                                                            id='num_usuarios'
                                                            value={numDocuments}
                                                            disabled={disabledDoc}
                                                            onChange={(e) => validateNumDocument(e)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {planoSelecionado.type === 3 ? (
                                                            <>
                                                                <Input
                                                                    name="numdocumentos"
                                                                    label="Número de documentos"
                                                                    type="number"
                                                                    min="1" 
                                                                    id='num_usuarios'
                                                                    value={numDocuments}
                                                                    disabled={disabledDoc}
                                                                    onChange={(e) => validateNumDocument(e)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {planoSelecionado.type === 2 ? (
                                                                    <>
                                                                        <Input
                                                                            name="numreceita"
                                                                            label="Número de receitas"
                                                                            type="number"
                                                                            min="1" 
                                                                            id='num_usuarios'
                                                                            value={numPrescriptions}
                                                                            disabled={disabledPrescript}
                                                                            onChange={(e) => validateNumPrescript(e)}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {planoSelecionado.type === 6 ? (
                                                                            <>
                                                                                <Select 
                                                                                    label="Número de receitas"
                                                                                    options={[
                                                                                        {value: '20', label: '20 receitas'},
                                                                                        {value: '40', label: '40 receitas'},
                                                                                        {value: 'ilimitado', label: 'Ilimitado'}
                                                                                    ]}
                                                                                    value={numPrescipt}
                                                                                    onChange={(e) => validateNumPrescript(e)}
                                                                                    disabled={false}
                                                                                    className="select"
                                                                                    id='prescript'
                                                                                    selectUniqueOption={false}
                                                                                />
                                                                                
                                                                                <Input
                                                                                    name="numUsuarios"
                                                                                    label="Número de usuários"
                                                                                    type="number"
                                                                                    min="1" 
                                                                                    id='num_usuarios'
                                                                                    value={numUsersId}
                                                                                    disabled={disabledNumUsers}
                                                                                    onChange={(e) => validateNumUsuarios(e)}
                                                                                />
                                                                            </>
                                                                        ) : (null)}
                                                                    
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
        
                                            </>
                                        )}
        
                                        <div className="valores">
                                            {planoSelecionado.is_pre_pago === 1 ? (
                                                <>
                                                    <h3 className="valor"><label>R$ {valorPlano} </label> por receita</h3>
                                                    {valorTotal ? (
                                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                                    ):(
                                                        <p className="total">Valor Total: R$ 0,00</p>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {planoSelecionado.type === 5 ? (
                                                        <>
                                                            {valorPlano ? (
                                                                <>
                                                                    {renovacao.value === '2' ? (
                                                                        <h3 className="valor"><label>R$ {valorPlano}</label> mensais/usuário</h3>
                                                                    ) : (
                                                                        <>
                                                                            {renovacao.value === '3' ? (
                                                                                <h3 className="valor"><label>R$ {valorPlano}</label> semestral/usuário</h3>
                                                                            ) : (
                                                                                <h3 className="valor"><label>R$ {valorPlano}</label> anual/usuário</h3>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            ):(
                                                                <>
                                                                    {renovacao.value === '2' ? (
                                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/usuário</h3>
                                                                    ) : (
                                                                        <>
                                                                            {renovacao.value === '3' ? (
                                                                                <h3 className="valor"><label>R$ 0,00</label> semestral/usuário</h3>
                                                                            ) : (
                                                                                <h3 className="valor"><label>R$ 0,00</label> anual/usuário</h3>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            
                                                            
                                                            {valorTotal ? (
                                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                                            ):(
                                                                <p className="total">Valor Total: R$ 0,00</p>
                                                            )}
                                                        </>
                                                    ): (
                                                        <>
                                                            {planoSelecionado.type === 4 ? (
                                                                <>
                                                                    {valorPlano ? (
                                                                        <>
                                                                            {renovacao.value === '2' ? (
                                                                                <h3 className="valor"><label>R$ {valorPlano} </label> mensais/receitas e documentos</h3>
                                                                            ) : (
                                                                                <>
                                                                                    {renovacao.value === '3' ? (
                                                                                        <h3 className="valor"><label>R$ {valorPlano} </label> semestral/receitas e documentos</h3>
                                                                                    ) : (
                                                                                        <h3 className="valor"><label>R$ {valorPlano} </label> anual/receitas e documentos</h3>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                        
                                                                    ):(
                                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/receitas e documentos</h3>
                                                                    )}
                                                                    
                                                                    
                                                                    {valorTotal ? (
                                                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                                                    ):(
                                                                        <p className="total">Valor Total: R$ 0,00</p>
                                                                    )}
                                                                </>
                                                            ): (
                                                                <>
                                                                {planoSelecionado.type === 3 ? (
                                                                        <>
                                                                            {valorPlano ? (
                                                                                <>
                                                                                    {renovacao.value === '2' ? (
                                                                                        <h3 className="valor"><label>R$ {valorPlano} </label> mensais/documentos</h3>
                                                                                    ) : (
                                                                                        <>
                                                                                            {renovacao.value === '3' ? (
                                                                                                <h3 className="valor"><label>R$ {valorPlano} </label> semestral/documentos</h3>
                                                                                            ) : (
                                                                                                <h3 className="valor"><label>R$ {valorPlano} </label> anual/documentos</h3>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ):(
                                                                                <h3 className="valor"><label>R$ 0,00</label> mensais/documentos</h3>
                                                                            )}
                                                                            
                                                                            
                                                                            {valorTotal ? (
                                                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                                                            ):(
                                                                                <p className="total">Valor Total: R$ 0,00</p>
                                                                            )}
                                                                        </>
                                                                    ): (
                                                                        <>
                                                                            {planoSelecionado.type === 2 ? (
                                                                                <>
                                                                                    {valorPlano ? (
                                                                                        <>
                                                                                            {renovacao.value === '2' ? (
                                                                                                <h3 className="valor"><label>R$ {valorPlano} </label> mensais/receitas</h3>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {renovacao.value === '3' ? (
                                                                                                        <h3 className="valor"><label>R$ {valorPlano} </label> semestral/receitas</h3>
                                                                                                    ) : (
                                                                                                        <h3 className="valor"><label>R$ {valorPlano} </label> anual/receitas</h3>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    ):(
                                                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/receitas</h3>
                                                                                    )}
                                                                                    
                                                                                    
                                                                                    {valorTotal ? (
                                                                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                                                                    ):(
                                                                                        <p className="total">Valor Total: R$ 0,00</p>
                                                                                    )}
                                                                                </>
                                                                            ): (
                                                                                <>
                                                                                    {planoSelecionado.type === 6 ? (
                                                                                        <>
                                                                                            {valorPlano ? (
                                                                                                <>
                                                                                                    {renovacao.value === '2' ? (
                                                                                                        <h3 className="valor"><label>R$ {valorPlano}</label> mensais/usuário</h3>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {renovacao.value === '3' ? (
                                                                                                                <h3 className="valor"><label>R$ {valorPlano}</label> semestral/usuário</h3>
                                                                                                            ) : (
                                                                                                                <h3 className="valor"><label>R$ {valorPlano}</label> anual/usuário</h3>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            ):(
                                                                                                <>
                                                                                                    {renovacao.value === '2' ? (
                                                                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/usuário</h3>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {renovacao.value === '3' ? (
                                                                                                                <h3 className="valor"><label>R$ 0,00</label> semestral/usuário</h3>
                                                                                                            ) : (
                                                                                                                <h3 className="valor"><label>R$ 0,00</label> anual/usuário</h3>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                            
                                                                                            
                                                                                            {valorTotal ? (
                                                                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                                                                            ):(
                                                                                                <p className="total">Valor Total: R$ 0,00</p>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (null)}
                                                                                </>
                                                                            )} 
                                                                            
                                                                        </>
                                                                    )} 
                                                                </>
                                                            )}  
                                                        </>
                                                    )}  
                                                </>
                                            )}
        
                                        </div>
        
                                        {props.isOff === true ? (
                                            null
                                        ) : (
                                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px', marginBottom: '60px' }}>
                                                <Button 
                                                    width='40%'
                                                    height='50px'
                                                    value='VOLTAR'
                                                    outline={true}
                                                    disabled={false}
                                                    onClick={() => props.locProp.history.goBack()}
                                                    loading={false}
                                                />
                                                <Button 
                                                    width='40%'
                                                    height='50px'
                                                    value={props.isSimulation === false ? 'AVANÇAR' : 'ADQUIRA JÁ'}
                                                    outline={false}
                                                    disabled={disabled}
                                                    onClick={() => nextStep()}
                                                    loading={false}
                                                />
                                            </div>
                                        )}
        
                                    </form>
        
                                )}
                            </Container>
                        </FormContainer>
                    ) : (null)}
                </>
            )}

        </>
    );
};

export default CompraStep0;
