export const CardsData = [
    {
        id: 1,
        number: "01",
        text: "Clique no botão abaixo para gerar seu cupom exclusivo.",
        highlight: "gerar seu cupom"
    },
    {
        id: 2,
        number: "02",
        text: "Compartilhe seu cupom com quantas pessoas quiser.",
        highlight: "Compartilhe"
    },
    {
        id: 3,
        number: "03",
        text: "Seu indicado ganha 20% de desconto em qualquer plano da AgroReceita.",
        highlight: "20% de desconto"
    },
    {
        id: 4,
        number: "04",
        text: "Ganhe 1 mês grátis ou R$60 em créditos para cada venda com seu cupom.",
        highlight: "1 mês grátis ou R$60 em créditos"
    },
];