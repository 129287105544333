import React, { useState, useEffect } from "react";

import Select from "../../components/select-search/index";
import api from "../../services/api";
import { store } from "react-notifications-component";
import { Container, ScreenClassRender } from "react-grid-system";
import ImageSearch from "../../assets/compendiocartoon.svg";
import { FormContainerCompendioGratis } from "./styles";
import Card from "../../components/card-compendio/index";
import EmitirReceitaStep1 from "../../pages/receitas/add";
import ReCAPTCHA from "react-google-recaptcha";
import DotsLoader from '../../components/loader';


import ModalGeneric from "../../components/modal/modalGeneric";
import ButtonV2 from "../../components/button/button_v2";
import GenericUpper from "../../components/generic-upper";
import {Footer} from "../../components/footer";

export const compendioG = "@compendioG";

const Compendio = ({ ...props }) => {

    const [culturaId, setCulturaId] = useState('');
    const [pragaId, setPragaId] = useState('');
    const [produtoId, setProdutoId] = useState('');
    const [culturas, setCulturas] = useState("");
    const [pragas, setPragas] = useState("");
    const [produtos, setProdutos] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [limitConsulta, setLimitConsulta] = useState(false);

    const [disabledCultura, setDisabledCultura] = useState(true);
    const [disabledPraga, setDisabledPraga] = useState(true);
    const [disabledProduto, setDisabledProduto] = useState(true);

    const [emitir, setEmitir] = useState(0); 
    
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [screenSize, setScreenSize] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [relacoes, setRelacoes] = useState([]);

    const [screen, setScreen] = useState([]);

    const [styleSelectField, setStyleSelectField] = useState(false);

    const [diaria, setDiaria] = useState(false);
    const [recaptcha, setRecaptcha] = useState(0);
    const [loadRecaptcha, setLoadRecaptcha] = useState(false);
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState('');
   
    const getScreenSize = screensize => {
        const sizesToHide = ["xl", "lg"];
        const hide = sizesToHide.includes(screensize);
        const prevScreenSize = screenSize;

        if (prevScreenSize !== screensize) {
            if (hide) {
                setScreenSize(screensize);
                setIsMobile(false);
                
            } else {
                setScreenSize(screensize);
                setIsMobile(true);
            }
        }
    };

    const updateToPrescriptionStepOne = (
        culturaId,
        produtoId,
        pragaId,
    ) => {
        setCulturaId(culturaId);
        setProdutoId(produtoId);
        setPragaId(pragaId);
        setEmitir(2);
    };

    const formatCulturas = culturas => {
        culturas = culturas.map(cultura => {
            let format = {
                value: cultura.id,
                label: `${cultura.nome_comum}\n - ${cultura.nome_cientifico}`
            };
            
            return format;
        });
        let len = culturas.length;
        culturas[len] = {value:'*', label:''};
        return culturas;
    };

    const formatPragas = pragas => {
        pragas = pragas.map(praga => {
            const format = {
                value: praga.id,
                label: `${praga.nome_comum}\n - ${praga.nome_cientifico}`
            };
            return format;
        });
        let len = pragas.length;
        pragas[len] = {value:'*', label:''};
        return pragas;
    };

    const formatProdutos = produtos => {
        produtos = produtos.map(produto => {
            const format = {
                value: produto.id,
                label: `${produto.nome_comercial}\n - ${produto.nome_tecnico}`
            };
            return format;
        });
        let len = produtos.length;
        produtos[len] = {value:'*', label:''};
        return produtos;
    };

    const reloadSelect = reload => {
        setCulturaId('');
        setProdutoId('');
        setPragaId('');

        const getCulturas = async () => {
            const response = await api.get(`/culturas`);
            let culturas = response.data.data;
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
        }
        const getProdutos = async () => {
            const response = await api.get(`/produtos`);
            let produtos = response.data.data;
            produtos = formatProdutos(produtos);
            
            setProdutos(produtos);
        }
        const getPragas = async () => {
            const response = await api.get(`/pragas`);
            let pragas = response.data.data;
            pragas = formatPragas(pragas);
            
            setPragas(pragas);
        }

        getCulturas();
        getProdutos();
        getPragas();
    }

    const handleClick = async click => {
        if(click == 1){
            if(open1 == false){
                if(open2 == false && open3 == false){
                    setOpen1(true);
                    setIsOpen(true);
                }else{
                    if(open2 == true){
                        setOpen2(false);
                        setOpen1(true);
                        setIsOpen(true);

                    }
                    if(open3 == true){
                        setOpen3(false);
                        setOpen1(true);
                        setIsOpen(true);
                    }
                }
            }else{
                setOpen1(false);
                setIsOpen(false);
            }
        }

        if(click == 2){
            if(open2 == false){
                if(open1 == false && open3 == false){
                    setOpen2(true);
                    setIsOpen(true);
                }else{
                    if(open1 == true){
                        setOpen1(false);
                        setOpen2(true);
                        setIsOpen(true);

                    }
                    if(open3 == true){
                        setOpen3(false);
                        setOpen2(true);
                        setIsOpen(true);
                    }
                }
            }else{
                setOpen2(false);
                setIsOpen(false);
            }
        }

        if(click == 3){
            if(open3 == false){
                if(open1 == false && open2 == false){
                    setOpen3(true);
                    setIsOpen(true);
                }else{
                    if(open1 == true){
                        setOpen1(false);
                        setOpen3(true);
                        setIsOpen(true);

                    }
                    if(open2 == true){
                        setOpen2(false);
                        setOpen3(true);
                        setIsOpen(true);
                    }
                }
            }else{
                setOpen3(false);
                setIsOpen(false);
            }
        }
    }

    const searchRelation = async () => {
        if(loadRecaptcha == false){
            setRecaptcha(1);
            setLoadRecaptcha(true);
        } else {
            try{
                const response = await api.get(`leads`);
                if(response.data.data.consultas <= 5){
                    setDiaria(true);
    
                    if(produtoId.value != "*" && culturaId.value != "*" && pragaId.value != "*"){
                        const response = await api.get(`/relacoes/compendio/${produtoId.value}/${culturaId.value}/${pragaId.value}`);
                        const relacoes = response.data.data;
                        setRelacoes(relacoes);
                    }
                }else{
                    if(response.data.data == "O limite diário já foi atingido! Para continuar consultando faça login."){
                        setDiaria(true);
                        let val = localStorage.getItem("@compendioG");
                        if(val != 1){
                            setLimitConsulta(true);
                        }
                    }
                }
    
            }catch(error){
                setDiaria(true);
                let val = localStorage.getItem("@compendioG");
                if(val != 1){
                    setLimitConsulta(true);
                }
            }
        }

    }

    const getRelations = async () => {
        try {
            const response = await api.get(`/compendio/${produtoId.value || "*"}/${culturaId.value ||"*"}/${pragaId.value || "*"}/*`);

            let culturas = response.data.data.culturas;
            let pragas = response.data.data.pragas;
            let produtos = response.data.data.produtos;

            setDisabledCultura(true);

            setCulturas([""]);
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
            setDisabledCultura(false);

            setDisabledPraga(true);
            setPragas([""]);
            pragas = formatPragas(pragas);
            setPragas(pragas);
            setDisabledPraga(false);

            setDisabledProduto(true);
            setProdutos([""]);
            produtos = formatProdutos(produtos);
            setProdutos(produtos);
            setDisabledProduto(false);

        } catch (e) {
            const errors = e;

            setCulturas("");
            setPragas("");
            setProdutos("");
            reloadSelect();

            store.addNotification({
                title: "Erro!",
                message: `${errors.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const getCulturas = async () => {
        try {
            setCulturas([""]);
            setDisabledCultura(true);
            const response = await api.get(`/culturas`);
            let culturas = response.data.data;
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
            setDisabledCultura(false);
            setDisabledCultura(false);
        } catch (error) {
            
        }
    }
    const getProdutos = async () => {
        try {
            setProdutos([""]);
            setDisabledPraga(true);
            const response = await api.get(`/produtos`);
            let produtos = response.data.data;
            produtos = formatProdutos(produtos);
            setProdutos(produtos);
            setDisabledProduto(false);
        } catch (error) {
            
        }

    }
    const getPragas = async () => {
        try {
            setPragas([""]);
            setDisabledProduto(true);
            const response = await api.get(`/pragas`);
            let pragas = response.data.data;
            pragas = formatPragas(pragas);
            setPragas(pragas);
            setDisabledPraga(false);
        } catch (error) {
            
        }

    }

    const clickPage = async () => {
        if(isOpen == true){
            setIsOpen(false);
            
        }
    }

    const onChange = (value) => {
        setRecaptcha(2);
        searchRelation();
    }
    const closeModal = () => {
        if(limitConsulta == true){
            setLimitConsulta(false);
            setRelacoes([]);
        }
    }
    const responseModal = valor => {
        if (valor == 0) {
            closeModal();
            window.open(`https://sistema.agroreceita.com.br/cadastro/free${params}`);
        } else {
            closeModal();
            window.open('https://sistema.agroreceita.com.br');

        }
    }

    const clearSearch = async () => {
        document.location.reload(true);
    }

    

    useEffect(() => { 
        getCulturas();
        getProdutos();
        getPragas();

        localStorage.setItem(compendioG, 0);

        let search = props.location.search;

        if (search) {
            search = search.split('?');
            search = search[1] + '&utm_id=compendio-gratis';
            
            if (search) {
                setParams(`?${search}`);
            }
        } else {
            setParams(`?utm_id=compendio-gratis`);

        }
    }, [])

    useEffect(() => {
        if (disabledCultura == false && disabledPraga == false && disabledProduto == false) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [disabledCultura, disabledPraga, disabledProduto])

    useEffect(() => {
        if (!culturaId && !produtoId && !pragaId) {
            getCulturas();
            getPragas();
            getProdutos();
        } else if (culturaId && produtoId && pragaId) {
            searchRelation();
        } else if (culturaId || produtoId || pragaId) {
            getRelations();
        }

    }, [culturaId, produtoId, pragaId]);

    useEffect(()=>{
        if(recaptcha != 1){
            if(window.screen.width <= 768){
                if(styleSelectField){
                    const test = document.getElementById('click');
                    test.style.height = "217px"
                    const form = document.getElementById('FormSelect');
                    form.style.height = "400px"
                }else{
                    const form = document.getElementById('FormSelect');
                    form.style.height = "auto"
                    const test2 = document.getElementById('click');
                    test2.style.height = "50px"
                }
            }
        }
    },[styleSelectField])

    useEffect(()=>{
        let val = localStorage.getItem("@compendioG");
        if(val == 1){
            setDiaria(false);
        }
    },[diaria])

    useEffect(()=>{
        setScreen(window.screen);
    },[window.screen])


    return (
        <ScreenClassRender 
            onClick={clickPage}
            render={screenClass => (<>

            <ModalGeneric
                show={limitConsulta}
                onHide={() => closeModal()}
                size='md'
            >
                <h2 style={{
                    fontFamily: 'Lato', 
                    fontSize: '22px', 
                    fontWeight: 'bold', 
                    fontStretch: 'normal', 
                    width: '100%', 
                    textAlign: 'center', 
                    alignSelf: 'center'
                }}> Você atingiu o limite diário de consultas! </h2>

                <p style={{
                    color: 'rgb(48, 56, 72)',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    width: '100%',
                    textAlign: 'center',
                    alignSelf: 'center'
                }}>Cadastre-se gratuitamente no Agroreceita para continuar consultando o Compêndio.</p>

                <div 
                    style={{ 
                        width: '100%', 
                        margin: 'auto',
                        marginTop: '20px', 
                        alignSelf: 'center', 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <ButtonV2
                        width='45%'
                        height='40px'
                        value='JÁ TENHO CADASTRO'
                        outline={true}
                        disabled={false}
                        onClick={() => responseModal(1)}
                        loading={false}
                        color="#21262F"
                    />
                    
                    <ButtonV2
                        width='45%'
                        height='40px'
                        value='CADASTRAR'
                        outline={false}
                        disabled={false}
                        onClick={() => responseModal(0)}
                        loading={false}
                    />
                </div>

            </ModalGeneric>
                {getScreenSize(screenClass)}

                {emitir == 0 ? (
                    <>
                        <GenericUpper 
                            isSite={true}
                            title={'Compêndio agrícola grátis'}
                            subtitle={'Realize a consulta fitossanitária dos defensivos agrícolas registrados no Brasil, de forma gratuita.'}
                            params={params}
                        />
                        
                        <FormContainerCompendioGratis styleSelectField={styleSelectField} isMobile={isMobile} isOpen={isOpen} onClick={clickPage}>
                            <Container style={{paddingBottom: '70px'}}>
                                {recaptcha == 1 ? (<>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <ReCAPTCHA
                                            sitekey="6Lfvwe8UAAAAAGoaFTyTDuvno6WPg7JuXL6-uqpW"
                                            onChange={onChange}
                                        />
                                    </div>
                                </>) : (<>
                                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                        <form id="FormSelect">
                                            <div 
                                                className="click" 
                                                onClick={() => handleClick(1)}
                                            >
                                                <Select
                                                    name="agro_culture_id"
                                                    className="select"
                                                    placeholder="Cultura"
                                                    id="cultura"
                                                    value={culturaId.value}
                                                    isClearable
                                                    onChange={cultura => cultura ? setCulturaId(cultura) : setCulturaId("")}
                                                    options={culturas}
                                                    isMobile={isMobile}
                                                    disabled={disabledCultura}
                                                />
                                            </div>

                                            <div 
                                                className="click" 
                                                onClick={() => handleClick(2)}
                                            >
                                                <Select
                                                    name="agro_pest_id"
                                                    className="select"
                                                    placeholder="Praga"
                                                    id="praga"
                                                    value={pragaId.value}
                                                    isClearable
                                                    onChange={praga => (praga ? setPragaId(praga) : setPragaId(""))}
                                                    options={pragas}
                                                    disabled={disabledPraga}
                                                    
                                                />
                                            </div>

                                            <div 
                                                id="click"
                                                className="click" 
                                                onClick={() => handleClick(3)}
                                            >
                                                <Select
                                                    name="agro_product_id"
                                                    className="select"
                                                    placeholder="Produto"
                                                    id="produto"
                                                    value={produtoId.value}
                                                    isClearable
                                                    onChange={produto =>
                                                        produto ? setProdutoId(produto) : setProdutoId("")
                                                    }
                                                    options={produtos}
                                                    disabled={disabledProduto}
                                                    onMenuOpen={()=>{setStyleSelectField(true)}}
                                                    onMenuClose={()=>{setStyleSelectField(false)}}
                                                />
                                            </div>
                                        
                                        </form>
                                        <div className="boxClear">
                                            <label onClick={clearSearch}>Limpar pesquisa</label>
                                        </div>
                                        <div className="loading" style={{width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                                            <DotsLoader show={loading}></DotsLoader>
                                        </div>


                                        <div style={{marginTop: '-91px'}} className="botaolabel">

                                        </div>
                                    </div>


                                    {culturaId && pragaId && produtoId ? (
                                        <Card
                                            cultura={culturaId}
                                            praga={pragaId}
                                            produto={produtoId}
                                            screen={screen}
                                            relacoes={relacoes}
                                            emitir={2}
                                            update={updateToPrescriptionStepOne}
                                            isMobile={isMobile}
                                            paramsUtm={params}
                                        >
                                        </Card>
                                    ) : (<>
                                        <div className="info">
                                            <div className="text">
                                                <label>Preencha os campos acima para começar...</label>
                                            </div>
                                            <div className="image-search">
                                                <img src={ImageSearch} width="300" />
                                            </div>
                                        </div>
                                    </>)}

                                </>)}

                            
                            </Container>
                            <Footer />

                        </FormContainerCompendioGratis>
                    </>
                ) : (
                    <>
                        <EmitirReceitaStep1
                            produtoId={produtoId}
                            pragaId={pragaId}
                            culturaId={culturaId}
                        />
                    </>)}
                </>)}
        />
    );
};

export default Compendio;
