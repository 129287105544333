import styled from "styled-components";
import { theme } from "../../styles/theme";

export const ContainerFooter = styled.div`
    width: 100%;
    height: auto;
    padding: 3rem 3rem 1.75rem;
   
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 1.5rem;
    background-color: #21262f;

    @media (max-width: 767px) {
        padding: 2rem 2rem 1.75rem;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    max-width: 71.25rem;

    gap: 2.75rem;
    display: flex;
    flex-direction: column;
`;

export const Information = styled.div`
    width: 100%;
    height: auto;

    gap: 1.25rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
        gap: 2rem;
        flex-direction: column;
    }
`;

export const Column = styled.div`
    width: auto;
    height: auto;

    gap: 2rem;
    display: flex;
    flex-direction: column;

    >a >p{
        cursor: pointer;
    }
`;

export const ContainerTopics = styled.div`
    width: 100%;
    height: auto;

    gap: 0.9688rem;
    display: flex;
    flex-direction: column;

    >a{
        >p{
            cursor: pointer;
        }
    
        img{
            width: 10.625rem;
            height: auto;
         }
    }

    >p {
        display: flex;
    
        >a{
            font-weight: 400;
            font-size: 0.875rem;
            font-family: "Lato", Sans-serif;
            color: ${theme.colors.blue.Azul_link};
        }
    }
`;

export const ContainerSocial = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    gap: 2.1875rem;
    margin-top: 1.6875rem;
    flex-direction: column;

    @media (max-width: 479px) {
        margin: 0.9375rem 0 0.3125rem;
    }
`;

export const Social = styled.div`
    width: 100%;
    height: auto;

    gap: 1.75rem;
    display: flex;

    >a { 
        width: auto;
        height: 3rem;

        display: flex;
        align-items: center;

        >i{
            color: #FFFFFF;
            font-size: 24px;
        }
    }
`;

export const ContainerApp = styled.div`
    width: 100%;
    height: auto;

    gap: 0.2813rem;
    display: flex;
    flex-direction: column;

    a{
        img{
            width: 10.625rem;
            height: auto;
         }
    }
`;

export const ContainerCopy = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    justify-content: space-between;

    p{
        text-align: center;

        color: #8A92A2;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5em;
        font-family: "Lato", Sans-serif;
    }

    @media (max-width: 767px) {
        gap: 1.75rem;
        align-items: center;
        flex-direction: column;
    }
`;

export const Copy = styled.div`
    width: auto;
    height: auto;

    gap: 0.75rem;
    display: flex;

    >a > p{
       cursor: pointer;
    }
`;