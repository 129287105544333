import React, { useEffect, useState } from "react";
import { useLocation, BrowserRouter } from "react-router-dom"; // Importe useLocation e BrowserRouter
import Routes from "./routes";
import GlobalStyles, { Layout, Main } from "./styles/global";
import { ScreenClassProvider } from "react-grid-system";
import ReactNotification from "react-notifications-component";
import Navbar from "../src/components/navbar";
import BlackNovember from "../src/components/countdown-black-system/index";
import { logged } from "./services/auth";

export const USER = "@userinfo";

const MainApp = () => {
  const [isFull, setIsFull] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [routeConfig, setRouteConfig] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isBlackNovemberVisible, setIsBlackNovemberVisible] = useState(false);
  const [blackNovember, setBlackNovember] = useState(false)
  const [statusLogged, setStatusLogged] = useState(localStorage.getItem("@logged"))

  const currentDate = new Date();
  const targetDate = new Date('2025-03-16T23:59:59');

  const userPlan = localStorage.getItem('@plan_name');
  const location = useLocation();

  const checkRouteInAllowedRoutes = () => {
    const storedRoutes = localStorage.getItem('allowedRoutes');

    if (storedRoutes) {
      const allowedRoutes = JSON.parse(storedRoutes);

      const currentPath = location.pathname;

      if (allowedRoutes.includes(currentPath)) {
        setIsBlackNovemberVisible(true);
      } else {
        setIsBlackNovemberVisible(false);
      }
      
    }
  };

  const handleBlackNovemberVisibility = (isVisible) => {
    setIsBlackNovemberVisible(isVisible);

    if (currentDate > targetDate || userPlan === "PLANO PRO") {
      setIsBlackNovemberVisible(!isVisible);
    }
  };

  const closeAllTabsFromNavBar = () => {
    setCloseAllTabs(true);
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("@logged");

    if (isLogged) {
      setStatusLogged(true);
      setBlackNovember(isLogged ? true : false);
    }else{
      setStatusLogged(false);
    }
  }, [statusLogged]);

  useEffect(() => {
    checkRouteInAllowedRoutes();
  }, [location.pathname]); 

  return (
    <Layout>
      <Navbar
        updateIsFullScreen={(e) => setIsFull(e)}
        isOpen={(e) => setIsOpen(e)}
        closeAllTabs={closeAllTabs}
        isClosed={() => setCloseAllTabs(!closeAllTabs)}
        updateIsMobile={(e) => setIsMobile(e)}
        routeConfig={routeConfig}
        isBlackNovember={isBlackNovemberVisible}
      />
      <Main
        isFull={isFull}
        isOpen={isOpen}
        onClick={() => closeAllTabsFromNavBar()}
      >

        { blackNovember === true ? (<BlackNovember onVisibilityChange={handleBlackNovemberVisibility}/>): (<></>)}

        <Routes
          isMobile={isMobile}
          isOpen={isOpen}
          upConfig={(e) => setRouteConfig(e)}
        />
      </Main>
    </Layout>
  );
};

const App = () => {
  return (
    <ScreenClassProvider>
      <ReactNotification />

      <GlobalStyles />

      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </ScreenClassProvider>
  );
};

export default App;
