import { theme } from "./theme";
import styled from "styled-components";

export const Display = styled.h1`
    font-size: 3rem;
    letter-spacing: 0;
    line-height: 3.6rem;
    font-family: "Lato";
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H1 = styled.h1`
    font-size: 2.5rem;
    letter-spacing: 0;
    font-family: "Lato";
    line-height: 3.25rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H2 = styled.h2`
    letter-spacing: 0;
    font-size: 2.25rem;
    font-family: "Lato";
    line-height: 2.925rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H3 = styled.h3`
    font-size: 2rem;
    letter-spacing: 0;
    line-height: 2.6rem;
    font-family: "Lato";
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H4 = styled.h4`
    letter-spacing: 0;
    font-size: 1.75rem;
    font-family: "Lato";
    line-height: 2.275rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H5 = styled.h5`
    font-size: 1.5rem;
    letter-spacing: 0;
    font-family: "Lato";
    line-height: 1.95rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const H6 = styled.h6`
    letter-spacing: 0;
    font-size: 1.25rem;
    font-family: "Lato";
    line-height: 1.625rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyG = styled.p`
    font-family: "Lato";
    font-size: 1.125rem;
    line-height: 1.8rem;
    letter-spacing: 0rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 400)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyGSpan = styled.span`
    font-family: "Lato";
    font-size: 1.125rem;
    line-height: 1.8rem;
    letter-spacing: 0rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 700)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyM = styled.p`
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 400)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyMSpan = styled.span`
    font-size: 1rem;
    line-height: 1.6rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 700)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyP = styled.p`
    font-size: 0.875rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    line-height: 1.3125rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 400)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const BodyPSpan = styled.span`
    font-size: 0.875rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    line-height: 1.3125rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 700)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const Auxiliar = styled.p`
    font-size: 0.75rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    line-height: 1.125rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 400)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const AuxiliarSpan = styled.span`
    font-size: 0.75rem;
    font-family: "Lato";
    letter-spacing: 0rem;
    line-height: 1.125rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 700)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const Minimo = styled.p`
    font-family: "Lato";
    font-size: 0.625rem;
    letter-spacing: 0rem;
    line-height: 0.9375rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 400)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const MinimoSpan = styled.span`
    font-family: "Lato";
    font-size: 0.625rem;
    letter-spacing: 0rem;
    line-height: 0.9375rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 700)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglineG = styled.p`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.6875rem;
    letter-spacing: 0.1rem;
    line-height: 1.0313rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 600)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglineGSpan = styled.span`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.6875rem;
    letter-spacing: 0.1rem;
    line-height: 1.0313rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglineM = styled.p`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.625rem;
    line-height: 0.9375rem;
    letter-spacing: 0.1rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 600)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglineMSpan = styled.span`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.625rem;
    line-height: 0.9375rem;
    letter-spacing: 0.1rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 800)}    ;
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglineP = styled.p`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.5625rem;
    letter-spacing: 0.1rem;
    line-height: 0.8438rem;
    font-weight:${props => (props.fontWeight ? props.fontWeight : 600)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`

export const TaglinePSpan = styled.span`
    text-transform: uppercase;

    font-family: "Lato";
    font-size: 0.5625rem;
    letter-spacing: 0.1rem;
    line-height: 0.8438rem;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 800)};
    color: ${props => (props.color ? props.color : theme.colors.gray.Cinza_2)};
`