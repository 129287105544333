import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import 'animate.css';
import Countdown from 'react-countdown';

import IconCloseBlack from '../../assets/icons/icon-close-black.svg';

import * as S from './styles';

export const compendioG = "@compendioG";

const allowedRoutes = [
    "/planos"
];

const CountdownBlackLp = ({ onVisibilityChange, ...props }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [isVisible, setIsVisible] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [utmUrl, setUtmUrl] = useState('/planos?utm_term=NONE&utm_source=sistema&utm_medium=contador-topo&utm_campaign=consumidor');
    const [rotas, setRotas] = useState([]);

    const initializeOrUpdateRoutes = () => {
        const storedRoutes = localStorage.getItem('allowedRoutes');
        if (!storedRoutes) {
            localStorage.setItem('allowedRoutes', JSON.stringify(allowedRoutes));

            setRotas(allowedRoutes);
        } else {
            const parsedStoredRoutes = JSON.parse(storedRoutes);

            const newRoutes = allowedRoutes.filter(route => !parsedStoredRoutes.includes(route));
            
            if (newRoutes.length > 0) {
                const updatedRoutes = [...parsedStoredRoutes, ...newRoutes];
                localStorage.setItem('allowedRoutes', JSON.stringify(updatedRoutes));
                setRotas(updatedRoutes);
            } else {
                setRotas(parsedStoredRoutes);
            }
        }
    };

    useEffect(() => {
        initializeOrUpdateRoutes();
    }, []);

    const getUtmUrl = () => {
        try {
            const currentUrl = new URL(window.location.href);
            const utmParams = new URLSearchParams(currentUrl.search);

            const hasUtm = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some(param => utmParams.has(param));

            if (!hasUtm) {
                utmParams.set('utm_term', 'NONE');
                utmParams.set('utm_source', 'sistema');
                utmParams.set('utm_medium', 'contador-topo');
                utmParams.set('utm_campaign', 'consumidor');
            }

            // Verifica se já tem o cupom, se não, adiciona
            if (!utmParams.has('cupom')) {
                utmParams.set('cupom', 'CONSUMIDOR30');
            }

            return `/planos?${utmParams.toString()}`;
        } catch (error) {
            console.error('Erro ao processar UTMs:', error);
            return '/planos?utm_term=NONE&utm_source=sistema&utm_medium=contador-topo&utm_campaign=consumidor';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setUtmUrl(getUtmUrl());
    }, [location.search]);

    const getDiscountText = () => {
        if (width <= 760) {
            return (
                <p>
                    <p>
                        Utilize o cupom <b>CONSUMIDOR30</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.
                    </p>
                    <p>
                        Válido para novos clientes até 16 de março de 2025.
                    </p>
                </p>

            );
        } else {
            return (
                <p>
                    Utilize o cupom <b>CONSUMIDOR30</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro. Válido para novos clientes até 16/03/2025.
                </p>
            );
        }
    };

    const currentDate = new Date();
    const targetDate = new Date('2025-03-16T23:59:59');


    const handleClose = () => {
        setIsAnimating(true);

        setTimeout(() => {
            setIsVisible(false);
            if (typeof onVisibilityChange === 'function') {
                onVisibilityChange(false);
            }
        }, 500);
    };

    useEffect(() => {
        onVisibilityChange(isVisible);
    }, [isVisible, onVisibilityChange]);


    const headTimerRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Evento encerrado!</span>;
        } else {
            return (
                <S.HeaderContainerCountdown>
                    <S.HeaderContainerBlock>
                        <span>{days}</span>

                        <span>dias</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{hours}</span>

                        <span>horas</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{minutes}</span>

                        <span>minutos</span>
                    </S.HeaderContainerBlock>

                    <S.HeaderContainerBlock>
                        <span>{seconds}</span>

                        <span>segundos</span>
                    </S.HeaderContainerBlock>
                </S.HeaderContainerCountdown>
            );
        }
    };

    if (!isVisible || currentDate > targetDate) return null;

    const containerClass = isAnimating ? 'animate__animated animate__fadeOutUp' : '';


    if (rotas.includes(currentPath)) {
        return (
            <S.HeadContainer className={containerClass}>
                <S.HeaderPromo>
                    <S.HeaderTimer>
                        <Countdown date={targetDate} renderer={headTimerRenderer} />
                    </S.HeaderTimer>

                    <S.HeadDescription>
                        {getDiscountText()}
                    </S.HeadDescription>

                    <a href={utmUrl}>
                        <button>
                            <p>Garantir meu desconto</p>
                        </button>
                    </a>

                    <button onClick={handleClose}>
                        <img src={IconCloseBlack} alt="Fechar" />
                    </button>
                </S.HeaderPromo>
            </S.HeadContainer>
        );
    }
};

export default CountdownBlackLp;