import React from 'react';
import { withRouter } from "react-router-dom";

import Tippy from '@tippyjs/react';

import {Footer} from '../../components/footer';
import { BannerHeader } from '../../components/lp-indique-e-ganhe/Banner-header';
import HowToParticipe from '../../components/lp-indique-e-ganhe/How-to-participate';
import FrequentQuestions from '../../components/lp-indique-e-ganhe/FrequentQuestions';

import IconTooltip from '../../assets/icons/icons-tooltip.svg'

import { theme } from '../../styles/theme';
import { Auxiliar, BodyG, BodyGSpan, BodyM, Display, H3, H6 } from '../../styles/stylesTypography';

import * as S from './styles';

export const compendioG = "@compendioG";

const LpIndiqueGanhe = ({ ...props }) => {

    const tooltipDiscont = (
        <div style={{ textAlign: 'left', padding: '10px', maxWidth: '300px', }}>
            <ul style={{ margin: 0, padding: '0 0 0 20px', listStyleType: 'disc' }}>
                <li>
                    <strong>Assinantes Mensal (Basic, Light ou Pro):</strong> Ganhe 20% de desconto nas 12 primeiras mensalidades para cada licença comprada com seu cupom.
                </li>

                <li>
                    <strong>Assinantes Anual (Basic, Light ou Pro):</strong> Ganhe 20% de desconto para cada licença anual comprada com seu cupom.
                </li>

                <li>
                    <strong>Clientes Pré-pago:</strong> Ganhe 20% de desconto na primeira compra com seu cupom.
                </li>
            </ul>
        </div>
    );

    const tooltipContent = (
        <div style={{ textAlign: 'left', padding: '10px', maxWidth: '300px' }}>
            <ul style={{ margin: 0, padding: '0 0 0 20px', listStyleType: 'disc' }}>
                <li>
                    <strong>Assinantes Mensal (Basic, Light ou Pro):</strong> Ganha uma mensalidade grátis para cada novo cliente que comprar com seu cupom exclusivo.
                </li>

                <li>
                    <strong>Assinantes Anual (Basic, Light ou Pro):</strong> Ganha 30 dias a mais no vencimento do seu plano para cada novo cliente que comprar com seu cupom exclusivo.
                </li>

                <li>
                    <strong>Clientes Pré-pago:</strong> Ganha R$ 60 de créditos pré-pago para cada novo cliente que comprar com seu cupom exclusivo (equivalente a 13 créditos de receituários).
                </li>
            </ul>
        </div>
    );

    return (
        <S.Container>
            <S.Wrapper>
                <BannerHeader />

                <S.ContainerWelcome>
                    <H3 color={theme.colors.gray.Cinza_2} fontWeight={700}>Bem-vindo ao nosso Programa Indique & Ganhe! Aqui na AgroReceita, valorizamos a confiança que nossos clientes depositam em nós. Agora, você pode compartilhar essa confiança com seus amigos e colegas e, ao mesmo tempo, ganhar benefícios exclusivos. Veja como é fácil participar e começar a ganhar!</H3>
                </S.ContainerWelcome>

                <HowToParticipe />

                <S.ContainerTable>
                    <S.Benefits>
                        <Display>Benefícios para todos</Display>

                        <BodyG>Confira os benefícios que você e seus indicados podem ganhar.</BodyG>
                    </S.Benefits>

                    <S.TableInformation>
                        <S.LineInfo>
                            <BodyGSpan fontWeight={800}>Seu indicado compra</BodyGSpan>

                            <BodyGSpan fontWeight={800}>Seu indicado ganha</BodyGSpan>

                            <BodyGSpan fontWeight={800}>Você ganha</BodyGSpan>
                        </S.LineInfo>

                        <S.LineDescription>
                            <BodyM>Qualquer plano usando <br /> seu cupom</BodyM>

                            <S.DescriptionandTooltip>
                                <BodyM>20% de desconto</BodyM>

                                <div>
                                    <Tippy
                                        content={tooltipDiscont}
                                        animation="fade"
                                        delay={[100, 100]}
                                        arrow={true}
                                        theme="light"
                                        placement="bottom"
                                    >

                                        <img src={IconTooltip} alt="Tooltip Icon" />
                                    </Tippy>
                                </div>
                            </S.DescriptionandTooltip>

                            <S.DescriptionandTooltip>
                                <BodyM>1 mensalidade grátis ou <br /> R$ 60 em créditos</BodyM>

                                <div>
                                    <Tippy
                                        content={tooltipContent}
                                        animation="fade"
                                        delay={[100, 100]}
                                        arrow={true}
                                        theme="light"
                                        placement="bottom"
                                    >

                                        <img src={IconTooltip} alt="Tooltip Icon" />
                                    </Tippy>
                                </div>
                            </S.DescriptionandTooltip>
                        </S.LineDescription>
                    </S.TableInformation>
                </S.ContainerTable>

                <FrequentQuestions />

                <S.ContainerService>
                    <H3 color={theme.colors.gray.Cinza_5}>Canais de atendimento</H3>

                    <S.Service>
                        <S.CardService>
                            <Auxiliar>Horário de atendimento</Auxiliar>

                            <H6 fontWeight={700}>Segunda à Sexta, das 8h30 às 18h</H6>
                        </S.CardService>

                        <S.CardService>
                            <Auxiliar>Telefone</Auxiliar>

                    
                            <a href="tel:+551532729780">
                                <H6 fontWeight={700}>(15) 3272.9780</H6>
                            </a>
                        </S.CardService>

                        <S.CardService>
                            <Auxiliar>Celular / Whatsapp</Auxiliar>

                            <a href="https://wa.me/5515981657739" target="_blank" rel="noopener noreferrer">
                                <H6 fontWeight={700}>(15) 98165-7739</H6>
                            </a>
                        </S.CardService>

                        <S.CardService>
                            <Auxiliar>E-mail</Auxiliar>
                            
                            <a href="mailto:vendas@agroreceita.com.br" target="_blank" rel="noopener noreferrer">
                                <H6 fontWeight={700}>vendas@agroreceita.com.br</H6>
                            </a>
                        </S.CardService>

                    </S.Service>
                </S.ContainerService>

                <S.ContainerFooter>
                    <Footer />
                </S.ContainerFooter>
            </S.Wrapper>
        </S.Container>
    );
};

export default withRouter(LpIndiqueGanhe);
