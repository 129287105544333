import React from 'react';
import * as S from './style';

import GooglePLay from '../../assets/icons/disponivel-google-play.svg'
import AppStore from '../../assets/icons/disponivel-app-store.svg'

import { theme } from '../../styles/theme';
import { Auxiliar, BodyM, BodyP } from '../../styles/stylesTypography';

export function Footer() {
    return (
        <S.ContainerFooter>
            <S.Wrapper>
                <S.Information>
                    <S.Column>
                        <BodyM fontWeight={800} color={theme.colors.white.Branco}>Empresa</BodyM>

                        <S.ContainerTopics>
                            <a href="https://agroreceita.com.br/sobre-nos/">
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Quem somos
                                </BodyP>
                            </a>

                            <a href="https://agroreceita.com.br/central-de-ajuda/">
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Central de ajuda
                                </BodyP>
                            </a>

                            <a href="https://agroreceita.com.br/ajuda/">
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Perguntas frequentes
                                </BodyP>
                            </a>

                            <a href="https://agroreceita.com.br/fale-conosco/">
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Fale conosco
                                </BodyP>
                            </a>

                            <a href="https://agroreceita.com.br/fale-com-especialista/">
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Solicite um especialista
                                </BodyP>
                            </a>
                        </S.ContainerTopics>

                        <a href="https://agroreceita.com.br/seja-um-parceiro">
                            <BodyM fontWeight={800} color={theme.colors.white.Branco}>
                                Seja um Parceiro
                            </BodyM>
                        </a>

                        <a href="https://agroreceita.com.br/materiais/">
                            <BodyM fontWeight={800} color={theme.colors.white.Branco}>
                                Materiais gratuitos
                            </BodyM>
                        </a>

                        <a href="https://agroreceita.com.br/blog/">
                            <BodyM fontWeight={800} color={theme.colors.white.Branco}>
                                Blog
                            </BodyM>
                        </a>
                    </S.Column>

                    <S.Column>
                        <BodyM fontWeight={800} color={theme.colors.white.Branco}>Funcionalidades</BodyM>

                        <S.ContainerTopics>
                            <a href='https://agroreceita.com.br/receita-agronomica/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Receita Agronômica
                                </BodyP>
                            </a>

                            <a href='https://sistema.agroreceita.com.br/cadastro'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Compêndio Agrícola Gratuito
                                </BodyP>
                            </a>

                            <a href='http://agroreceita.com.br/aplicativo-compendio-agricola'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Aplicativo Compêndio Agrícola
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/aplicativo-receituario-agronomico'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Aplicativo Receituário Agronômico
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/art-de-receituario-agronomico/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    ART – Anotação de Responsabilidade Técnica
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/trt-de-receituario-agronomico/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    TRT – Termo de Responsabilidade Técnica
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/sistema-integracao-receituario-agronomico'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    API de Integração
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/cadastro-de-clientes'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Cadastro de Clientes
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/assinatura-digital-e-eletronica/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Assinatura Eletrônica 
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/previsao-do-tempo'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Previsão do Tempo
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/ficha-emergencia-fispq-fds/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    FDS (FISPQ) e Ficha de Emergência
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/homologacao-defesas-agropecuarias/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Homologação junto às Defesas
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/relatorios-receituario-agronomico/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Métricas Essenciais
                                </BodyP>
                            </a>

                            <a href='https://agroreceita.com.br/crm/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    CRM e Agenda para o Agro
                                </BodyP>
                            </a>
                        </S.ContainerTopics>
                    </S.Column>

                    <S.Column>
                        <BodyM fontWeight={800} color={theme.colors.white.Branco}>Planos</BodyM>

                        <S.ContainerTopics>
                             <a href='https://agroreceita.com.br/planos/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Plano Basic
                                </BodyP>
                             </a>

                             <a href='https://agroreceita.com.br/planos/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Plano Light
                                </BodyP>
                             </a>

                             <a href='https://agroreceita.com.br/planos/'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Plano Pro
                                </BodyP>
                             </a>

                             <a href='https://agroreceita.com.br/pre-pago'>
                                <BodyP color={theme.colors.gray.Cinza_7}>
                                    Plano Pré-Pago
                                </BodyP>
                             </a>
                        </S.ContainerTopics>

                        <S.ContainerSocial>
                            <BodyM fontWeight={800} color={theme.colors.white.Branco}>Redes Sociais</BodyM>

                            <S.Social>
                                <a href="https://www.instagram.com/agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-instagram"></i>
                                </a>

                                <a href="https://www.facebook.com/AgroReceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-facebook"></i>
                                </a>

                                <a href="https://www.linkedin.com/company/agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-linkedin"></i>
                                </a>

                                <a href="https://youtube.com/@agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-youtube"></i>
                                </a>
                            </S.Social>
                        </S.ContainerSocial>
                    </S.Column>

                    <S.Column>
                        <BodyM fontWeight={800} color={theme.colors.white.Branco}>Contato</BodyM>

                        <S.ContainerTopics>
                            <a href='mailto:vendas@agroreceita.com.br'>
                                <BodyP color={theme.colors.blue.Azul_link}>
                                    vendas@agroreceita.com.br
                                </BodyP>
                            </a>
                        </S.ContainerTopics>

                        <S.ContainerTopics>
                            <p>
                                <a href='tel:+551532729780'>(15) 3272-9780</a>

                                <BodyP color={theme.colors.gray.Cinza_7}> &nbsp; / &nbsp;</BodyP>

                                <a href='tel:+5515981657739'>(15) 98165-7739</a>
                            </p>
                        </S.ContainerTopics>

                        <BodyP color={theme.colors.gray.Cinza_7}>R. Rosa Lotfi de Almeida Bueno, 155 Vila <br />Nastri II, Itapetininga (SP). CEP: 18.206-390</BodyP>

                        <BodyP color={theme.colors.gray.Cinza_7}>R. Isidoro Garcês, 77-3 Rio Tavares, <br />Florianópolis (SC). CEP: 88.048-444</BodyP>

                        <S.ContainerApp>
                            <a href='https://play.google.com/store/apps/developer?id=AgroReceita'>
                                <img src={GooglePLay} alt='' />
                            </a>

                            <a href='https://apps.apple.com/us/app/agroreceita-receita-agron%C3%B4mica/id1671808311'>
                                <img src={AppStore} alt='' />
                            </a>
                        </S.ContainerApp>
                    </S.Column>
                </S.Information>

                <S.ContainerCopy>
                    <S.Copy>
                        <a href='https://agroreceita.com.br/politica-de-privacidade/'>
                            <Auxiliar color={theme.colors.gray.Cinza_7}>Política de Privacidade</Auxiliar>
                        </a>

                        <Auxiliar color={theme.colors.gray.Cinza_7}>|</Auxiliar>

                        <a href='https://agroreceita.com.br/termos-de-uso/'>
                            <Auxiliar color={theme.colors.gray.Cinza_7}>Termos de Uso</Auxiliar>
                        </a>
                    </S.Copy>

                    <BodyP color={theme.colors.gray.Cinza_7}>2020-2025 AgroReceita ® © Todos os direitos reservados</BodyP>
                </S.ContainerCopy>
            </S.Wrapper>
        </S.ContainerFooter>
    )
}

export default Footer;