import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Button from "../../button/button_v2";
import ModalGeneric from '../../../components/modal/modalGeneric';

import { CardsData } from './CardsData';

import { theme } from '../../../styles/theme';
import { Auxiliar, BodyG, BodyGSpan, H2, H5 } from '../../../styles/stylesTypography';

import * as S from './styles';

const HowToParticipe = ({ history, ...props }) => {
    const [modalBlocked, setModalBlocked] = useState(false);
    
    const handleRedirect = () => {
        if (localStorage.getItem("@userinfo")) {
            const userPlan = JSON.parse(localStorage.getItem("@userinfo"));
            const getUSerPlan = userPlan?.contrato?.agro_plan_config?.nome_plano;

            if (getUSerPlan === "TESTE GRÁTIS") {
                setModalBlocked(true);
            } else {
                localStorage.setItem('IndiqueReload', 'true');
                window.location.href = '/cupom-indique-e-ganhe';
            }
        } else {
            localStorage.setItem('@loginReload', 'true');
            window.location.href = '/cupom-indique-e-ganhe';
        }
    };

    const RedirectPlan = () => {
        window.location.href = '/planos'; 
    }
     
    return (
        <S.Container>
            <S.Wrapper>
                <S.ContainerContent>
                    <S.ContentHeader>
                        <S.ContenTitle>
                            <H2>Como <br />Participar</H2>
                        </S.ContenTitle>
    
                        <BodyG color={theme.colors.gray.Cinza_5}>
                            <BodyGSpan color={theme.colors.gray.Cinza_5}>Qualquer cliente AgroReceita pode participar</BodyGSpan>. Basta aceitar os termos do programa e você
                            receberá um cupom exclusivo para compartilhar com seus contatos. Cada vez que alguém usar seu
                            cupom para adquirir a AgroReceita, você e seu indicado ganham!
                        </BodyG>
                    </S.ContentHeader>
    
                    <S.ContainerCards>
                        {CardsData.map((item) => (
                            <S.Card key={item.id}>
                                <H2 color={theme.colors.green.Verde_AgroReceita}>{item.number}</H2>
                                
                                <BodyG>
                                    {item.text.split(item.highlight).map((part, index) => (
                                        <React.Fragment key={index}>
                                            {part}
                                            {index < item.text.split(item.highlight).length - 1 && (
                                                <BodyGSpan>{item.highlight}</BodyGSpan>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </BodyG>
                            </S.Card>
                        ))}
                    </S.ContainerCards>
    
                    <S.ContainerButtonAndTerms>
                        <Button
                            width="206px"
                            height="54.5px"
                            value="GERAR MEU CUPOM"
                            outline={false}
                            disabled={false}
                            onClick={handleRedirect}
                            loading={false}
                        />

                         <Auxiliar>Ao gerar seu cupom, você aceita as
                            <S.LinkContainer>
                                <a href="https://agroreceita.com.br/regras-indique-e-ganhe">
                                    &nbsp; regras do programa
                                </a>
                            </S.LinkContainer>
                        </Auxiliar>
                    </S.ContainerButtonAndTerms>
                </S.ContainerContent>
            </S.Wrapper>

            <ModalGeneric
                show={modalBlocked}
                onHide={() => setModalBlocked(false)}
                >
                <S.ContainerModalBlocked>
                    <H5>Essa é uma função exclusiva para clientes</H5>
             
                    <BodyG>
                        Confira nossos planos e veja todas as funções disponiveis em cada um deles.
                    </BodyG>

                    <div className="button-finish" style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center',}}>
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => RedirectPlan()}
                            loading={false}
                        />
                    </div>
                </S.ContainerModalBlocked>
            </ModalGeneric> 
        </S.Container>
    );
}

export default withRouter(HowToParticipe);