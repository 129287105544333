import styled from "styled-components";

import Containerimg from '../../assets/combined.svg'

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    /* padding: 48px; */

    display: flex;
    align-items: center;
    align-items: flex-start;
    
    background-color: #FFFFFF;
    background-image: url(${Containerimg});
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
`;

export const Wrapper = styled.div` 
    width: 100%;
    height: 100%;
    padding: 0px 48px 48px;

    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >img{
        width: auto;
        height: 36px;
    }

`;

export const Content = styled.div` 
    width: 100%;
    height: auto;
    padding: 1.5rem 0;
    
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >img{
        width: auto;
        height: 160px;
    }

    >span{
        color: transparent;
    }
`

export const Description = styled.div` 
    width: 100%;
    height: auto;
    
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    >p{
        color: #303848;
        font-family: "Lato";
        font-size: 18px;
        font-weight: 400;
        line-height: 28.8px;
        text-align: center;

        >span{
            font-family: "Lato";
            font-size: 18px;
            font-weight: 700;
            line-height: 28.8px;
            text-align: center;
    }
    }

    >p + p{
        color: #FF5252;

        font-family: "Lato";
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
    }
`

export const Button = styled.button` 
    width: 142px;
    height: 56px;
    padding: 12px 40px;

    border: none;
    font-size: 11px;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: 800;
    text-align: center;
    line-height: 16.5px;
    font-family: "Lato";
    border-radius: 10rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background-color: #2FBB69;
`