import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import 'animate.css';
import Countdown from 'react-countdown';

import * as S from './styles';

export const compendioG = "@compendioG";

const CountdownBlack = ({ onVisibilityChange, ...props }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [isVisible, setIsVisible] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [utmUrl, setUtmUrl] = useState('/planos?utm_term=NONE&utm_source=sistema&utm_medium=contador&utm_campaign=consumidor');

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        try {
            const currentUrl = new URL(window.location.href);
            const utmParams = new URLSearchParams();
            
            ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
                const value = currentUrl.searchParams.get(param);
                if (value) {
                    utmParams.append(param, value);
                }
            });

            setUtmUrl(utmParams.toString() !== '' 
                ? `/planos?${utmParams.toString()}` 
                : '/planos?utm_term=NONE&utm_source=sistema&utm_medium=contador&utm_campaign=consumidor'
            );
        } catch (error) {
            console.error('Erro ao processar UTMs:', error);
            setUtmUrl('/planos?utm_term=NONE&utm_source=sistema&utm_medium=contador&utm_campaign=consumidor');
        }
    }, [location.search]);

    const currentDate = new Date();
    const targetDate = new Date('2025-03-16T23:59:59');

    useEffect(() => {
        onVisibilityChange(isVisible);
    }, [isVisible, onVisibilityChange]);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Evento encerrado!</span>;
        } else {
            return (
                <S.ContainerCountdown>
                    <S.ContainerBlock>
                        <span>{days}</span>

                        <span>dias</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{hours}</span>

                        <span>horas</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{minutes}</span>

                        <span>minutos</span>
                    </S.ContainerBlock>

                    <S.ContainerBlock>
                        <span>{seconds}</span>

                        <span>segundos</span>
                    </S.ContainerBlock>
                </S.ContainerCountdown>
            );
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        window.location.href = utmUrl;
    };

    if (!isVisible || currentDate > targetDate) return null;

    const userPLan = localStorage.getItem('@plan_name');

    if (currentPath === "/black-november-2024" || currentPath === "/receituario-agronomico-2025") {
        const containerClass = isAnimating ? 'animate__animated animate__fadeOutUp' : '';

        if (userPLan !== "PLANO PRO") {
            return (
                <S.Container className={containerClass}>
                    <S.Wrapper>
                        <S.ContainerBlack>
                            <S.InformationBlack>
                                <h1>Semana do Consumidor</h1>
                                <p>
                                    Utilize o cupom <b>CONSUMIDOR30</b> e garanta <b>30% de desconto</b> nos 12 primeiros meses dos planos Basic, Light ou Pro.
                                </p>
                                <span>Promoção válida para novos clientes até 16 de março de 2025.</span>
                            </S.InformationBlack>

                            <S.CountdownAndButton>
                                <Countdown date={targetDate} renderer={renderer} />

                                <a href={utmUrl} onClick={handleClick}>
                                    <button>
                                        <p>Garantir meu desconto</p>
                                    </button>
                                </a>
                            </S.CountdownAndButton>
                        </S.ContainerBlack>
                    </S.Wrapper>
                </S.Container>
            );
        } else {
            return null;
        }
    }

    return null;
};

export default CountdownBlack;