export const theme = {
    colors: {
        // Tons Verde
        green: {
            Verde_AgroReceita: '#2FBB69', 
            Bandeira: '#06B04D',
            Folha: '#03903E'
        },

        // Tons Azul
        blue: {
            Azul_link: '#589BFF', 
            Ceu: '#FFB74D',
        },

        // Tons Vermelho
        red: {
            Vermelho_erro: '#FF5252', 
            Tostado: '#D9261D',
        },

        // Tons Laranja
        orange: {
            Laranja_Sudeste: '#F89E4B', 
            Laranja: '#F4702C',
        },

        // Tons Amarelo
        yellow: {
            Amarelo: '#F8C84B', 
            Gema: '#F0B316',
        },

        // Tons Cinza
        gray: {
            Cinza_1: '#1D2129',
            Cinza_2: '#21262F',
            Cinza_3: '#292E38',
            Cinza_4: '#2D3340',
            Cinza_5: '#303848',
            Cinza_6: '#586377',
            Cinza_7: '#8A92A2',
            Cinza_8: '#BAC1CF',
            Cinza_9: '#CED2DC',
            Cinza_10: '#DFE4EB',
            Cinza_11: '#E9ECF0',
            Cinza_12: '#F3F3F5',
            Cinza_13: '#F8F8F9',
        },

        // Tons Branco
        white: {
            Branco: '#FFFFFF',
        },

        // Fundo Desabilitado
        disabled: {
            Fundo_Desabilitado: '#EEEEEE',
            Desabilitado_Claro: '#BBBBBB',
            Desabilitado_Medio: '#AAAAAA',
        },
    },
}; 