import React, { useEffect, useRef, useState } from 'react';

import 'animate.css';

import api from '../../services/api';
import { logout } from '../../services/auth';

import Notificação from '../../assets/icons/icon-bell-header.svg';
import ArrowDown from '../../assets/icons/icon-arrow-down-header.svg';

import * as S from './styles';
import { BodyMSpan, H4, TaglinePSpan } from '../../styles/stylesTypography';

export const USER = "@userinfo";
export const compendioG = "@compendioG";
export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';

const HeaderV2 = ({ ...props }) => {
    const [profileName, setProfileName] = useState('');

    const [userId, setUserId] = useState(0);
    const [userPlanId, setUserPlanId] = useState(0);
    const [diasRestantes, setDiasRestantes] = useState(0);

    const pathName = window.location.pathname;

    const userData = JSON.parse(localStorage.getItem("@userinfo"));
    const userGoogleData = JSON.parse(localStorage.getItem("@agroreceita_google_user"));

    const userFullName = userData?.name;
    const userShortName = userData?.name?.split(" ")[0];
    const userProfilePicture = userGoogleData?.profile?.picture;

    const [showProfile, setShowProfile] = useState(true)
    const [isAccordionOpen, setAccordionOpen] = useState(false);

    const toggleAccordion = () => setAccordionOpen(!isAccordionOpen);

    const [qtd_notification, setQtd_notification] = useState(0);

    const ShowName = () => {
        if (pathName === "/novo-produtores") {
            return null;
        } else {
            return (
                <S.UserName>
                    <H4>Olá, {userFullName}</H4>
                </S.UserName>
            );
        }
    };

    const exit = () => {
        if (userPlanId === 1) {
            if (diasRestantes === 7) {
            } else {
                setTimeout(() => {
                    localStorage.removeItem(GOOGLE_EMAIL);
                    localStorage.removeItem(GOOGLE_USER);
                    localStorage.removeItem(GOOGLE_USER_PROFILE);

                    window.location = '/login';
                }, 100);
            }
        } else {
            setTimeout(() => {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                window.location = '/login';
            }, 100);
        }
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));

        if (user) {
            let name = user.name;
            let id = user.id;

            name = name.substr(0, 2).toUpperCase();

            setProfileName(name);
            setUserId(id);

            if (user.contrato) {
                setUserPlanId(user.contrato.agro_plan_config.agro_plan_id);
                setDiasRestantes(user.contrato.dias_restantes);
            } else {
                logout();
                window.location = '/login';
            }
        }
    }, []);

    const getNotifications = async () => {
        try {
            const response = await api.get(`/notificacoes/quantidade`);
            const qtdr = response.data.data;

            setQtd_notification(qtdr);
        } catch (error) {
            console.log("Erro ao buscar notificações.")
        }
    }

    const photoOrName = () => {
        return userProfilePicture ? (
            <img src={userProfilePicture} alt="Imagem de perfil" />
        ) : (
            <S.NameProfile>
                <BodyMSpan>{profileName}</BodyMSpan>
            </S.NameProfile>
        );
    };

    const accordionRef = useRef(null);

    const handleClickOutside = (event) => {
        if (accordionRef.current && !accordionRef.current.contains(event.target)) {
            setAccordionOpen(false);
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);

            if (currentWidth <= 767) {
                setShowProfile(false)
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [width]);


    const getPlans = userData?.contrato?.agro_plan_config?.nome_plano;

    const buttonPlansChange = () => {
        switch (getPlans) {
            case "PLANO PRO":
                return null;

            case "PLANO LIGHT":
            case "PLANO BASIC":
                return (
                    <a href="/planos" style={{ display: getPlans === 'PLANO PRO' ? 'none' : 'flex' }}>
                        <S.ManagePlansButton>
                            <TaglinePSpan>GERENCIAR PLANO</TaglinePSpan>
                        </S.ManagePlansButton>
                    </a>
                );

            case "TESTE GRÁTIS":
                return (
                    <a href="/planos" style={{ display: getPlans === 'PLANO PRO' ? 'none' : 'flex' }}>
                        <S.SubscribeButton>
                            <TaglinePSpan>ASSINAR AGORA</TaglinePSpan>
                        </S.SubscribeButton>
                    </a>
                );

            default:
                return null;
        }
    };
            
    return (
        <S.Container>
            <S.ContainerSubscribeAndUser>
                {buttonPlansChange()}

                {ShowName()}
            </S.ContainerSubscribeAndUser>

            <S.ContainerNotificationAndProfile>
                <S.ContainerNotification hasNotification={qtd_notification > 0 ? true : false}>
                    <a href="/notificacao">
                        <img src={Notificação} alt="Icone de notificação" />
                    </a>
                </S.ContainerNotification>

                {showProfile === false ? '' : (
                    <S.Profile onClick={toggleAccordion} ref={accordionRef}>
                        {photoOrName()}

                        <TaglinePSpan>{userShortName}</TaglinePSpan>

                        <S.ButtonProfile>
                            <img
                                src={ArrowDown}
                                alt="Icone de flecha direcionada para baixo"
                                className={`animate__animated ${isAccordionOpen ? 'animate__rotateInDownUp' : 'animate__rotateInDown'
                                    }`}
                                style={{
                                    transform: isAccordionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease',
                                }}
                            />
                        </S.ButtonProfile>

                        {isAccordionOpen && (
                            <S.Accordion>
                                <a href={`/usuarios/${userId}`}>
                                    MEUS DADOS
                                </a>

                                {
                                    props.isMobile && props.planId && props.planId !== 5 && (
                                        <a href="/planos"> ATUALIZAÇÃO DE PLANO</a>
                                    )
                                }

                                <a href="/faturamento">
                                    DADOS DE FATURAMENTO
                                </a>

                                <a href="/filiais">
                                    MINHA EMPRESA
                                </a>

                                <a href="/usuarios">
                                    USUÁRIOS
                                </a>

                                <a href="/integracao">
                                    INTEGRAÇÃO
                                </a>

                                <S.AccordionItem onClick={() => exit()}>SAIR</S.AccordionItem>
                            </S.Accordion>
                        )}
                    </S.Profile>
                )}
            </S.ContainerNotificationAndProfile>
        </S.Container>
    );
};

export default HeaderV2;
