import React from 'react';
import { withRouter } from "react-router-dom";
import { ContainerPlanos } from "./styles";
import PlanTable from '../../components/planTable';
import CountdownBlackSystem from '../../components/countdown-black-lp';
import * as S from './styles'
import Logo from '../../assets/logo-agroreceita-black.svg'
import Footer from '../../components/footer';
import { useState } from 'react';

const Planos = ({ ...props }) => {
    const [isPromoVisible, setIsPromoVisible] = useState(false);

    const currentDate = new Date();
    const targetDate = new Date('2025-03-16T23:59:59');


    const handleBlackNovemberVisibility = (isVisible) => {
        setIsPromoVisible(isVisible);
    
        if (currentDate > targetDate) {
            setIsPromoVisible(!isVisible);
        }
      };
    return (
        <>
            <CountdownBlackSystem onVisibilityChange={handleBlackNovemberVisibility}/>

            <ContainerPlanos>
                <S.HeaderPlans>
                    <div class='image'>
                        <a href="https://agroreceita.com.br/">
                            <img src={Logo} alt="Logo AgroReceita" />
                        </a>
                    </div>

                    <div class='links'>
                        <a href="https://agroreceita.com.br/ajuda/">Ajuda</a>
                        <a href="">Planos</a>
                        <a href="https://agroreceita.com.br/blog/">Blog</a>
                    </div>

                    <div class='buttons-plans'>
                        <button onClick={() => window.location = '/login'}>Entrar</button>
                        <button onClick={() => window.location = '/cadastro'}>Teste Grátis</button>
                    </div>

                    <div class='mobile-only'>
                        <a href="https://agroreceita.com.br/ajuda/">Ajuda</a>
                        <a onClick={() => window.location = '/login'}>Entrar</a>
                    </div>
                </S.HeaderPlans>


                <PlanTable />
            </ContainerPlanos>

            <Footer />
        </>
    )
}

export default withRouter(Planos);
